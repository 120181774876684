/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let FeaturedData = function (featuredDataComponent) {
    this.featuredDataComponent = featuredDataComponent;
    this.toggleButton = this.featuredDataComponent.querySelector('.universalfeatureddata-toggle');
};

FeaturedData.prototype = {
    // Click toggle button to show / hide additional data
    toggleData: function () {
        var _this = this;
        if (this.toggleButton !== null) {
            this.toggleButton.addEventListener('click', function () {
                _this.featuredDataComponent.classList.toggle('showAll');
            });
        }
    }
};

window.addEventListener('load', function () {
    let allFeaturedDataComponents = document.querySelectorAll('.universalfeatureddata');
    if (allFeaturedDataComponents) {
        allFeaturedDataComponents.forEach(function (featuredDataComponent) {
            // Construct new FeaturedData
            var featuredData = new FeaturedData(featuredDataComponent);

            // Init functionality on load
            featuredData.toggleData();
        });
    }
});