function lazyLoadMoreResults() {

    // Wait for the 'ResultList' promise to resolve, then return the 'LoadMoreResults' implementation.
    return Coveo.load('ResultList').then(function () {

        var LoadMoreResults = (function (_super) {

            function LoadMoreResults(element, options, bindings) {

                this.type = 'LoadMoreResults';
                Coveo.Component.bindComponentToElement(element, this);
                this.element = element;
                this.options = Coveo.ComponentOptions.initComponentOptions(element, LoadMoreResults, options);
                this.bindings = bindings;

                var searchInterfaceId = this.bindings.searchInterface.element.id;
                var resultListElement = document.querySelector('#' + searchInterfaceId + ' .CoveoResultList');

                if (resultListElement) {
                    this.resultList = Coveo.get(resultListElement, Coveo.ResultList);
                    Coveo.$$(this.element).on('click', this.loadmoreResults.bind(this));
                    Coveo.$$(this.bindings.root).on("preprocessResults", this.preprocessResults.bind(this));
                }
            }

            LoadMoreResults.prototype.loadmoreResults = function () {
                this.resultList.displayMoreResults(10);
            };

            LoadMoreResults.prototype.preprocessResults = function (e, args) {

                if (args.results.totalCount > args.query.firstResult + args.query.numberOfResults) {
                    this.show();
                } else {
                    this.hide();
                }
            };

            LoadMoreResults.prototype.show = function () {
                this.setVisibility(true);
            };

            LoadMoreResults.prototype.hide = function () {
                this.setVisibility(false);
            };

            LoadMoreResults.prototype.setVisibility = function (show) {
                let firstElementInput = this.element.getElementsByTagName("input")[0];
                if (show) {
                    this.element.style.display = "block";
                    if (firstElementInput) {
                        firstElementInput.style.display = "inline-block";
                    }
                } else {
                    this.element.style.display = "none";
                    if (firstElementInput) {
                        firstElementInput.style.display = "none";
                    }
                }
            };

            LoadMoreResults.ID = 'LoadMoreResults';
            Coveo.Initialization.registerAutoCreateComponent(LoadMoreResults);
            return LoadMoreResults;
        }(Coveo.Component));

        return LoadMoreResults;
    });
}

try {
    // Register the 'LoadMoreResults' lazy component using the previously defined function.
    Coveo.LazyInitialization.registerLazyComponent('LoadMoreResults', lazyLoadMoreResults);
} catch (e) {
    console.log('Error: LoadMoreResults lazy component not registered.');
}