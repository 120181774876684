/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';
import 'owl.carousel2/dist/owl.carousel.min.js';

let ProductBar = function (productBar) {
    this.productBar = productBar;
    this.allProducts = this.productBar.querySelectorAll('.universalproductbar-list-item');
    this.allProductsWidth = 0;
    this.carouselElement = this.productBar.querySelector('.owl-carousel');
};

ProductBar.prototype = {
    init: function () {
        let _this = this;

        if (_this && _this.productBar) {
            _this.calculateAllProductsWidth(_this);
            _this.configureCarousel(_this);

            window.addEventListener('resize', function () {
                _this.configureCarousel(_this);
            });
        }
    },

    calculateAllProductsWidth: function (_this) {
        if (_this.allProducts) {
            _this.allProducts.forEach(function (product) {
                _this.allProductsWidth += product.offsetWidth;
            });
        }
    },

    configureCarousel: function (_this) {
        let productBarWidth = _this.productBar.getBoundingClientRect().width;
        if (productBarWidth >= _this.allProductsWidth) {
            // If product bar can hold all items
            $(_this.carouselElement).owlCarousel("destroy");
        }
        else {
            // If product bar cannot hold all items, make carousel
            $(_this.carouselElement).owlCarousel({
                nav: true,
                navText: [],
                autoHeight: true,
                responsive: {
                    0: {
                        items: 2
                    },
                    460: {
                        items: 3
                    },
                    600: {
                        items: 4
                    },
                    1100: {
                        items: 6
                    }
                }
            });
        }
    }
};

window.addEventListener('load', function () {
    let productBars = document.querySelectorAll('.universalproductbar');
    if (productBars) {
        productBars.forEach(function (productBar) {
            var newProductBar = new ProductBar(productBar);
            newProductBar.init();
        });
    }
});