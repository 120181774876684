export function getFromLocalStorage(stateName) {
    if (typeof Storage !== 'undefined') {
        return JSON.parse(localStorage.getItem(stateName));
    } else return null;
}

export function updateLocalStorage(stateName, stateObject) {
    if (typeof Storage !== 'undefined') {
        localStorage.setItem(stateName, JSON.stringify(stateObject));
    }
}

export function initNewSession(sessionPropertyName) {
    if (typeof Storage !== 'undefined') {
        sessionStorage.setItem(sessionPropertyName, true);
    }
}

export function sessionExists(sessionPropertyName) {
    if (typeof Storage !== 'undefined' && sessionStorage.getItem(sessionPropertyName)) {
        return true;
    } else return false;
}

export function isReturnVisit(localStorageStateName, sessionPropertyName) {
    if (typeof Storage !== 'undefined') {
        if (localStorage.getItem(localStorageStateName)) {
            // state exists so I've visited the site before
            if (!sessionStorage.getItem(sessionPropertyName)) {
                // I'm in a new session
                return true;
            } else {
                // I'm in an existing session
                return false;
            }
        }
        else {
            // I'm brand new, never visited before
            return false;
        }
    }
    else return null;
}

export function getData() {
    var convoUIElement = document.getElementsByClassName('universalconvoui');
    if (convoUIElement.length > 0) {
        var jqXHR = $.ajax({
            url: '/universalapi/Feature/Insights/ConvoUI/GetConvoUIData',
            type: 'GET',
            async: false
        });

        if (jqXHR.status === 200 && jqXHR.readyState === 4) {
            let parsedResponse = JSON.parse(jqXHR.responseText);
            if ($.isEmptyObject(parsedResponse)) {
                throw "Error: Empty object returned from GetConvoUIData, though GET request was successful. Make sure Sitecore items are configured correctly.";
            }
            else {
                return parsedResponse;
            }
        }
        else {
            throw `Error fetching Convo UI data. Status code: ${jqXHR.status}`;
        }
    }
    else {
        return null;
    }
}

export function postSearchFacets(facetName, guidToSave) {
    $.ajax({
        url: '/universalapi/Feature/Insights/ConvoUI/PostSearchFacets',
        type: 'POST',
        data: {
            'facetName': facetName,
            'guidToSave': guidToSave
        },
        success: function () {
            console.log("Successfully posted search facets.");
        },
        error: function () {
            console.log("Error posting search facets.");
        }
    });
}

export function getSearchRecommendations(categoryGuid, industryGuid) {
    var jqXHR = $.ajax({
        url: '/universalapi/Feature/Insights/ConvoUI/GetSearchRecommendations',
        type: 'GET',
        data: {
            'categoryAnswerGuid': categoryGuid,
            'industryAnswerGuid': industryGuid !== null ? industryGuid : ''
        },
        success: function () {
            console.log("Success fetching Convo UI recommendations.");
        },
        error: function () {
            console.log("Error fetching Convo UI recommendations.");
        },
        async: false
    });
    return JSON.parse(jqXHR.responseText);
}

export function getMappedRecommendations(allRecommendationsCards, defaultRecommendationsCards, selectedPrimaryTaxonomyName, selectedSecondaryTaxonomyName) {
    // Strings from JSON
    let primaryTaxonomyPropertyName = "PrimaryTaxonomyName";
    let secondaryTaxonomyPropertyName = "SecondaryTaxonomyName";

    // Arrays
    var comboMatches = [];
    var primaryNullSecondaryMatches = [];

    allRecommendationsCards.forEach(function (card) {
        if (selectedPrimaryTaxonomyName === card[primaryTaxonomyPropertyName]) {
            if (selectedSecondaryTaxonomyName === card[secondaryTaxonomyPropertyName]) {
                comboMatches.push(card);
            }
            else if (card[secondaryTaxonomyPropertyName] === null) {
                primaryNullSecondaryMatches.push(card);
            }
        }
    });

    return comboMatches.length > 0 ?
        comboMatches :
        primaryNullSecondaryMatches.length > 0 ?
            primaryNullSecondaryMatches :
            defaultRecommendationsCards;
}

export function updateSavedRecommendations(savedRecommendations, newRecommendations) {
    // Combine saved and new recommendations
    const combinedRecommendations = newRecommendations.concat(savedRecommendations);

    // Filter the combination of recommendations to remove any duplicate recommendations
    // Dupes are found by comparing the stringified objects so we can compare using all obj properties
    return combinedRecommendations.filter((item, index, self) => {
        return index === self.findIndex(obj => {
            return JSON.stringify(obj) === JSON.stringify(item);
        });
    });
}