import React from 'react';

const ConvoUIContentItem = (props) => {
    let tagList = [];
    function addTagsToList(tags) {
        tags.forEach(function (tag) {
            if (tag && tag.DisplayName) {
                tagList.push(tag.DisplayName);
            }
        });
    }
    addTagsToList([props.data.ContentType, props.data.Category, props.data.Industry]);

    function assitantClick() {
        UniversalAnalytics.SendEvent('Convo UI', 'Assistant New Recommendation Click', props.data.ContentTitle, 10);
    }

    return (
        <li className="universalconvoui-contentitem">
            {
                props.data.ImageUrl &&
                <img className="universalconvoui-contentitem-img" src={props.data.ImageUrl} alt={props.data.ImageAlt} />
            }
            <div className="universalconvoui-contentitem-text">
                {
                    tagList.length > 0 &&
                    <div className="universalconvoui-contentitem-tags">
                        {
                            tagList.map((tag, index) => {
                                return <small className="universalconvoui-contentitem-tags-item" key={index}>{tag}</small>;
                            })
                        }
                    </div>
                }
                {
                    props.data.Subtitle &&
                    <h2 className="universalconvoui-contentitem-subtitle">
                        {props.data.Subtitle}
                    </h2>
                }
                {
                    props.data.ContentTitle && props.data.Url &&
                    <a className="universalconvoui-contentitem-link" href={props.data.Url}
                        onClick={() => { props.closeSidebarHandler(); assitantClick(); }}>
                        <p className="universalconvoui-contentitem-title">
                            {props.data.ContentTitle}
                        </p>
                    </a>
                }
            </div>
        </li>
    );
};

export default ConvoUIContentItem;