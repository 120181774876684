/*jshint esversion: 6 */

import React from 'react';
import * as constants from '../../../appState/constants';
import DocumentLink from '../../shared/documentlink';

const NavItem = (props) => {

    const activeClass = props.isActiveNavItem ? ' navitem-active' : '';
    const videoProps = props.navItem.VideoProperties;
    const hasUploadedVideo = videoProps && videoProps.VideoType === constants.VIDEO_TYPES_ENUM.UPLOAD && videoProps.UploadedVideoUrl;
    const hasEmbeddedVideo = videoProps && videoProps.VideoType === (constants.VIDEO_TYPES_ENUM.YOUTUBE || constants.VIDEO_TYPES_ENUM.VIMEO) && videoProps.ExternalVideoId;
    const hasVideo = hasUploadedVideo || hasEmbeddedVideo;

    function openVideoModal() {
        let videoMarkup;

        if (hasUploadedVideo) {
            videoMarkup = `<video preload='auto' controls='controls'><source src=${videoProps.UploadedVideoUrl} type='video/mp4' /></video>`;
        } else {
            videoMarkup = `<div class='infographics-modal-iframe-container'><iframe src=${videoProps.ExternalVideoBaseUrl}/${videoProps.ExternalVideoId} frameBorder='0' webkitallowfullscreen mozallowfullscreen allowFullScreen /></div>`;
        }

        let modalMarkup = document.createElement('div');
        modalMarkup.classList.add('infographics-modal');
        modalMarkup.innerHTML = videoMarkup;
        document.body.appendChild(modalMarkup);

        modalMarkup.addEventListener('click', function (e) {
            if (e.target === modalMarkup) {
                modalMarkup.remove();
            }
        });
    }

    function infographicsGALevelClick(activeData) {
        let levelClassName = props.levelClassName;

        let infographicAction = "Level 1 Click";

        if (levelClassName.indexOf('two') !== -1) {
            infographicAction = "Level 2 Click";
        }
        UniversalAnalytics.SendEvent('Infographics', infographicAction, activeData.Title, 10);
    }

    function infographicsGACardButtonClick(activeData) {
        UniversalAnalytics.SendEvent('Infographics', "Infographics Card Button Click", activeData.Button.Text, 10);
    }

    function infographicsGACardLinkClick(activeData) {
        UniversalAnalytics.SendEvent('Infographics', "Infographics Card Link Click", activeData.Link.Text, 10);
    }

    function infographicsGACardVideoClick(activeData) {
        UniversalAnalytics.SendEvent('Infographics', "Infographics Card Video Click", activeData.Title, 10);
    }

    return (
        <div className={'infographics-sidenav-navitem' + activeClass}>
            <h2 className='infographics-sidenav-navitem-title' onClick={() => {
                props.navItemClickHandler(props.navItem);
                infographicsGALevelClick(props.navItem);
            }}>
                {props.navItem.Title}
            </h2>

            {
                props.isActiveNavItem &&
                <div className='infographics-sidenav-navitem-details'>

                    {
                        props.navItem.ImageUrl &&
                        <div
                            className={'infographics-sidenav-navitem-details-image-container' + (hasVideo ? ' hasVideo' : '')}
                            onClick={() => {
                                hasVideo ? openVideoModal() : null;
                                infographicsGACardVideoClick(props.navItem);
                            }}
                        >
                            <img
                                className='infographics-sidenav-navitem-details-image'
                                src={props.navItem.ImageUrl}
                            />
                        </div>
                    }

                    {
                        props.navItem.Description &&
                        <p className='infographics-sidenav-navitem-details-description'>
                            {props.navItem.Description}
                        </p>
                    }

                    {
                        props.navItem.Documents &&
                        <div className='infographics-sidenav-navitem-details-documents'>
                            {
                                props.navItem.Documents.map((document, index) => {
                                    return (
                                        <DocumentLink
                                            key={index}
                                            document={document}
                                        />
                                    );
                                })
                            }
                        </div>
                    }

                    {
                        props.navItem.Link && props.navItem.Link.Url && props.navItem.Link.Text &&
                        <a
                            className='infographics-sidenav-navitem-details-link'
                            href={props.navItem.Link.Url}
                            onClick={() => {
                                infographicsGACardLinkClick(props.navItem);
                            }}
                            target='_blank'
                        >
                            {props.navItem.Link.Text}
                        </a>
                    }

                    {
                        props.navItem.Button && props.navItem.Button.Url && props.navItem.Button.Text &&
                        <a
                            className='infographics-sidenav-navitem-details-button'
                            href={props.navItem.Button.Url}
                            onClick={() => {
                                infographicsGACardButtonClick(props.navItem);
                            }}
                            target='_blank'
                        >
                            {props.navItem.Button.Text}
                        </a>
                    }

                </div>
            }

        </div>
    );
};

export default NavItem;