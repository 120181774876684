/*jshint esversion: 6 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as constants from './appState/constants';
import configureStore from './appState/configureStore';
import ConvoUIPromptContainer from './app/prompt-container';
import ConvoUINav from './app/shared/nav';
import ConvoUIClose from './app/shared/close';
import ConvoUIPanelContainer from './app/panel-container';

if (constants.PROMPT_CONTAINER_REACTROOT
    && constants.NAV_REACTROOT
    && constants.CLOSE_REACTROOT
    && constants.PANEL_CONTAINER_REACTROOT
    && constants.SIDEBAR_ELEMENT
    && constants.CONTACT_ELEMENT) {

    const store = configureStore();

    ReactDOM.render(
        <Provider store={store}>
            <ConvoUIPromptContainer />
        </Provider>,
        constants.PROMPT_CONTAINER_REACTROOT
    );

    ReactDOM.render(
        <Provider store={store}>
            <ConvoUINav />
        </Provider>,
        constants.NAV_REACTROOT
    );

    ReactDOM.render(
        <Provider store={store}>
            <ConvoUIClose />
        </Provider>,
        constants.CLOSE_REACTROOT
    );

    ReactDOM.render(
        <Provider store={store}>
            <ConvoUIPanelContainer />
        </Provider>,
        constants.PANEL_CONTAINER_REACTROOT
    );
}