/*jshint esversion: 6 */

import React from 'react';
import ActionIndicator from './child-components/actionindicator';
import ContextPopup from './child-components/contextpopup';
import BackButton from '../shared/backbutton';

const ImageMap = (props) => {
    return (
        <div className='infographics-imagemap'>

            {
                !props.isInitialState && (props.hideSideNav || props.isMobile) &&
                <BackButton
                    backButtonClickHandler={props.backButtonClickHandler}
                    backLinkText={props.backLinkText}
                />
            }

            <div className='zoom'>
                <div className='zoomChild'>

                    <img
                        className='infographics-imagemap-image'
                        src={props.data.ImageMapUrl}
                        onClick={(e) => { props.imageMapClickHandler(e) }}
                    />

                    {
                        props.data.NavItems.map((navItem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <ActionIndicator
                                        navItem={navItem}
                                        actionIndicatorClickHandler={props.actionIndicatorClickHandler}
                                        sideNavSlideHandler={props.sideNavSlideHandler}
                                        isActive={props.activeSubcategoryData === navItem}
                                        actionIndicatorCustomIconUrl={props.actionIndicatorCustomIconUrl}
                                    />

                                    {
                                        !props.isMobile && navItem.PopupData && navItem === props.activeSubcategoryData &&
                                        <ContextPopup
                                            navItem={navItem}
                                        />
                                    }
                                </React.Fragment>
                            );
                        })
                    }

                </div>
            </div>

        </div>
    );
};

export default ImageMap;