/*jshint esversion: 6 */

(function () {
    window.jci = window.jci || {};
    //var $ = jQuery;

    window.jci.userLocation = {
        init: function (url, units, autocompleteId, useGoogle, bingApiKey, shouldRedirectAutomatically) {
            this.shouldRedirectAutomatically = shouldRedirectAutomatically;
            this.url = url;
            this.units = units;
            this.autocompleteId = autocompleteId;

            this.initialAddress = this.getUrlParameter('address') ? this.getUrlParameter('address') : null;
            if (this.initialAddress) {
                this.setLocationInputVal(this.initialAddress);
            }

            this.bing = new window.jci.bing(bingApiKey);
            this.constants = window.jci.locationFinderConstants;
            if (!useGoogle) {

                //use bing
                // check location on load only if it should redirect on load
                if (this.shouldRedirectAutomatically) {
                    this.getBingLocation();
                }

                $('.find-locations-button').click(this.getBingLocation.bind(this));
                $('.location-input-container input')
                    .keypress(function(e) {
                        if (e.which === 13) {
                            this.getBingLocation();
                        }
                    }.bind(this));
            } else {
                //use google

                if (this.initialAddress) {
                    this.getGooglePlaceFromAddress();
                }

                navigator.geolocation.getCurrentPosition(this.userLocationSuccess.bind(this),
                    this.userLocationError.bind(this));
                this.initAutocomplete(autocompleteId);
                $('.find-locations-button').click(this.redirect.bind(this));

            }

            $("input").focus(function() {
                $(this).on("click.a",
                    function(e) {
                        $(this).off("click.a").select();
                    });
            });
        },

        setLocationInputVal: function(text) {
            $('#' + this.autocompleteId).val(text);
        },

        getLocationInputVal: function () {
            return $('#' + this.autocompleteId).val();
        },

        getBingLocation: function() {
            var locationInput = this.getLocationInputVal();

            if (locationInput) {

                var success = function(response) {
                    if (this.bing.hasCoordinate(response)) {
                        var coordinates = response.resourceSets[0].resources[0].point.coordinates;
                        this.lat = coordinates[0];
                        this.lng = coordinates[1];
                        this.redirect();
                    } else {
                        this.setLocationInputVal(this.constants.InvalidAddressMessage);
                    }
                };

                this.bing.getLocationFromAddress(locationInput, success.bind(this));
            } else {
                // input box was blank
            }
        },

        createCORSRequest: function(method, url) {
            var xhr = new XMLHttpRequest();

            if ("withCredentials" in xhr) {
                xhr.open(method, url, true);
            } else if (typeof XDomainRequest !== "undefined") {
                xhr = new XDomainRequest();
                xhr.open(method, url);
            } else {
                xhr = null;
            }

            return xhr;
        },

        getBingUrl: function(query) {
            return 'http://dev.virtualearth.net/REST/v1/Locations?q=' +
                encodeURIComponent(query) +
                '&key=' +
                this.bingKey;
        },

        getGooglePlaceFromAddress: function() {
            var geocoder = new google.maps.Geocoder();

            if (geocoder) {
                geocoder.geocode({ 'address': this.initialAddress }, this.handleGooglePlaceGeocode.bind(this));
            }
        },

        handleGooglePlaceGeocode: function(predictions, status) {
            if (status !== 'OK') {
                this.setLocationInputVal(this.constants.invalidAddressMessage);
                return;
            }

            if (predictions && predictions.length) {
                var result = predictions[0].geometry.location;
                this.lat = result.lat();
                this.lng = result.lng();

                this.redirect();
            }
        },

        locationChanged: function() {
            var place = this.autocomplete.getPlace();
            if (place.geometry) {
                this.lat = place.geometry.location.lat();
                this.lng = place.geometry.location.lng();
                this.redirect();
            }
        },

        initAutocomplete: function(autocompleteId) {
            var options = {
                types: ['geocode']
            };

            var input = document.getElementById(autocompleteId);

            this.pacSelectFirst(input);
            this.autocomplete = input ? new google.maps.places.Autocomplete(input, options) : null;

            if (this.autocomplete) {
                this.autocomplete.addListener('place_changed', this.locationChanged.bind(this));
            }
        },


        pacSelectFirst: function(input) {
            // store the original event binding function
            var _addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent;

            function addEventListenerWrapper(type, listener) {
                // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
                // and then trigger the original listener.
                if (type === "keydown") {
                    var orig_listener = listener;
                    listener = function(event) {
                        var suggestion_selected = $(".pac-item-selected").length > 0;
                        if (event.which === 13 && !suggestion_selected) {
                            var simulated_downarrow = $.Event("keydown",
                                {
                                    keyCode: 40,
                                    which: 40
                                });
                            orig_listener.apply(input, [simulated_downarrow]);
                        }

                        orig_listener.apply(input, [event]);
                    };
                }

                _addEventListener.apply(input, [type, listener]);
            }

            input.addEventListener = addEventListenerWrapper;
            input.attachEvent = addEventListenerWrapper;
        },

        userLocationSuccess: function (position) {
            if (position && position.coords) {
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;

                window.history.pushState({}, '', window.location);

                if (this.shouldRedirectAutomatically) {
                    this.redirect();
                }
            }
        },

        userLocationError: function (response) {
            $('.find-locations-button').click(this.redirect.bind(this));
        },

        redirect: function () {
            if (this.lat && this.lng && this.url) {
                window.location =
                    this.url + "?latitude=" + this.lat + "&longitude=" + this.lng + "&units=" + this.units;
            }
        },

        getUrlParameter: function(name) {
            name = name.toLowerCase().replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }
    };
})();