/*jshint esversion: 6 */

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as constants from './appState/constants';
import InfographicsContainer from './app/infographics-container';
import configureStore from './appState/configureStore';
import './zoomPinch';

if (constants.INFOGRAPHICS_REACTROOT) {

    const store = configureStore();

    render(
        <Provider store={store}>
            <InfographicsContainer />
        </Provider>,
        constants.INFOGRAPHICS_REACTROOT
    );

}