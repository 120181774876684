/*jshint esversion: 6 */

(function () {
    window.jci = window.jci || {};
    //var $ = jQuery;

    window.jci.locationFinder = {
        init: function(autocompleteId,
            useGoogle,
            bingApiKey,
            nearbyLocationsUrl,
            nearbyLocationsWithSearchTermsUrl,
            nearbyLocationsOfTypeUrl,
            nearbyLocationsOfTypeWithSearchTermsUrl,
            reportLocationsUrl,
            closestPreferredVendorUrl,
            closestPreferredVendorWithSearchTermsUrl,
            closestPreferredVendorOfTypeUrl,
            closestPreferredVendorOfTypeWithSearchTermsUrl,
            resultsLanguage,
            units,
            backgroundImage) {
            var pageSize = 10;
            this.replaceHistoryState = true;
            this.autocompleteId = autocompleteId;
            this.useGoogle = useGoogle;
            this.backgroundImage = backgroundImage;
            this.iso = resultsLanguage;
            this.units = units;

            this.google = {
                mapMarkers: []
            };

            this.constants = window.jci.locationFinderConstants;
            this.extractQueryParams();

            this.distanceArray = [10, 25, 50, 100];
            this.nearbyLocationsUrl = nearbyLocationsUrl;
            this.nearbyLocationsWithSearchTermsUrl = nearbyLocationsWithSearchTermsUrl;
            this.nearbyLocationsOfTypeUrl = nearbyLocationsOfTypeUrl;
            this.nearbyLocationsOfTypeWithSearchTermsUrl = nearbyLocationsOfTypeWithSearchTermsUrl;
            this.reportLocationsUrl = reportLocationsUrl;
            this.closestPreferredVendorUrl = closestPreferredVendorUrl;
            this.closestPreferredVendorWithSearchTermsUrl = closestPreferredVendorWithSearchTermsUrl;
            this.closestPreferredVendorOfTypeUrl = closestPreferredVendorOfTypeUrl;
            this.closestPreferredVendorOfTypeWithSearchTermsUrl = closestPreferredVendorOfTypeWithSearchTermsUrl;
            this.zIndex = 1;
            this.mapMarkerNumber = 1;

            this.bing = new window.jci.bing(bingApiKey);

            this.initGoogle();
            this.initBing();

            this.model = new window.jci.locationFinderViewModel(pageSize);

            this.addMiscBindings();
        },

        extractQueryParams: function() {
            this.latitude = this.getUrlParameter('latitude') ? this.getUrlParameter('latitude') : null;
            this.longitude = this.getUrlParameter('longitude') ? this.getUrlParameter('longitude') : null;
            this.distance = this.getUrlParameter('distance');

            if (this.distance && this.distance.toLowerCase() !== 'none') {
                this.distance = parseInt(this.getUrlParameter('distance'));
            } else {
                this.distance = this.distance ? 'none' : 10;
            }

            this.units = this.getUrlParameter('units') ? this.getUrlParameter('units') : this.units;
            this.locationTypeId =
                this.getUrlParameter('locationtypeid') ? this.getUrlParameter('locationtypeid') : null;
            this.initialAddress = this.getUrlParameter('address') ? this.getUrlParameter('address') : null;
            this.searchQuery = this.getUrlParameter('searchquery') ? this.getUrlParameter('searchquery') : null;

            if (this.initialAddress) {
                this.setLocationInputVal(this.initialAddress);
            }

            // iso in the query param overrides the language set on datasource. Results will fall back to default language
            this.iso = this.getUrlParameter('iso') ? this.getUrlParameter('iso') : this.iso;

            this.milesLabel = this.units === "mi" ? "miles" : "kilometers";
        },

        initGoogle: function() {
            if (this.useGoogle) {
                this.initGoogleMap(this);
                this.setGoogleLocation();
            }
        },

        initBing: function() {
            if (!this.useGoogle) {
                $('#location-finder-map').css('background-image', "url(" + this.backgroundImage + ")");

                if (this.latitude && this.longitude) {
                    this.bing.getAddressFromLatLng(this.latitude, this.longitude, this.setLocationInputVal.bind(this));
                    this.bing.foundLocation = true;
                    this.getLocationResults(this.getAllResultsRequestUrl(), this.getAllResultsSuccess);
                } else if (this.initialAddress) {
                    this.getBingLocation();
                }

                $('#address-search').click(this.getBingLocation.bind(this));
                $('.location-input input').keypress(function(e) {
                    if (e.which === 13) {
                        this.getBingLocation();
                    }
                }.bind(this));
            }
        },

        initGoogleMap: function(page) {
            page.google.mapBounds = new google.maps.LatLngBounds();
            var uluru = { lat: 43.038902, lng: -87.906471 };
            page.google.map = new google.maps.Map(document.getElementById('location-finder-map'),
                {
                    zoom: 4,
                    center: uluru
                });

            this.initAutocomplete();
        },

        consumeBingLocation: function(response) {
            if (this.bing.hasCoordinate(response)) {
                this.bing.foundLocation = true;
                var coordinates = response.resourceSets[0].resources[0].point.coordinates;

                this.latitude = coordinates[0];
                this.longitude = coordinates[1];

                this.setLocationInputVal(response.resourceSets[0].resources[0].name);
                this.getLocationResults(this.getAllResultsRequestUrl(), this.getAllResultsSuccess);
            } else {
                this.bing.foundLocation = false;
                this.model.applyNewLocations([]);
            }
        },

        getBingLocation: function() {
            var address = this.getLocationInputVal();
            this.bing.foundLocation = false;

            if (address) {
                this.bing.getLocationFromAddress(address, this.consumeBingLocation.bind(this));
            }
        },

        getLocationInputVal: function() {
            return $('#' + this.autocompleteId).val();
        },

        setLocationInputVal: function(val) {
            $('#' + this.autocompleteId).val(val);
        },

        DistanceOption: function(distance, unit, labelFunction) {
            this.distance = distance;
            this.unit = unit;

            this.label = labelFunction ? labelFunction : function(label) {
                    return this.distance + " " + label;
                };
        }.bind(this),

        getDistanceOptions: function() {
            var options = $(this.distanceArray)
                .map(function(index, distance) {
                    return new this.DistanceOption(distance, this.units);
                }.bind(this));

            options.push(new this.DistanceOption("none", "miles", function() { return "All"; }));

            return options;
        },

        googleLocationChosen: function() {
            var place = this.google.autocomplete.getPlace();

            if (place && place.geometry) {
                this.latitude = place.geometry.location.lat();
                this.longitude = place.geometry.location.lng();

                this.getLocationResults(this.getAllResultsRequestUrl(), this.getAllResultsSuccess);
            }
        },

        increaseRadius: function() {
            var nextRadius = $(this.model.distanceOptions()).map(function(index, distance) {
                if (distance.distance === this.distance) {
                    if (index !== this.model.distanceOptions().length - 1) {
                        return this.model.distanceOptions()[index + 1];
                    }

                    return this.model.distanceOptions()[index];
                }
            }.bind(this));

            this.model.selectedDistance(nextRadius[0]);
            this.model.distanceChanged();
        },

        getLocationResults: function(requestUrl, successCallback) {
            if (requestUrl) {
                var xhr = this.createCORSRequest('GET', requestUrl);

                if (history.pushState) {
                    var newUrl = window.location.origin +
                        window.location.pathname +
                        this.getNearbyLocationsQueryString();

                    if (requestUrl.toLowerCase().indexOf('preferredvendor') <= 0) {
                        if (this.replaceHistoryState) {
                            window.history.replaceState({ path: newUrl }, '', newUrl);
                        } else {
                            window.history.pushState({ path: newUrl }, '', newUrl);
                        }
                    }

                    this.getBitlyUrl(newUrl);
                }

                if (!xhr) {
                    return;
                }

                xhr.onload = successCallback.bind(this);

                xhr.onerror = function(response) {
                    alert('CORS failure');
                }.bind(this);

                xhr.send();
                this.replaceHistoryState = false;
            }
        },

        getBitlyUrl: function (newUrl) {
            var accessToken = 'b2e387c151c816ec18ef23e3c853488263f58c1d';
            var params = {
                'long_url': newUrl
            };
            $.ajax({
                url: "https://api-ssl.bitly.com/v4/shorten",
                cache: false,
                dataType: "json",
                method: "POST",
                contentType: "application/json",
                beforeSend: function (xhr) {
                    xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
                },
                data: JSON.stringify(params)
            }).done(function (data) {
                $('#shareURL').val(data.link);
            }).fail(function (data) {
                console.log(data.link);
            });
        },

        createCORSRequest: function(method, url) {
            var xhr = new XMLHttpRequest();

            if ("withCredentials" in xhr) {
                xhr.open(method, url, true);
            } else if (typeof XDomainRequest !== "undefined") {
                xhr = new XDomainRequest();
                xhr.open(method, url);
            } else {
                xhr = null;
            }

            return xhr;
        },

        getNearbyLocationsQueryString: function() {
            if (this.latitude && this.longitude) {
                var distance = this.distance === "none" ? "none" : this.distance;
                var string = "";

                string = "?latitude=" +
                    this.latitude +
                    "&longitude=" +
                    this.longitude;

                string = this.searchQuery ? string + '&searchquery=' + this.searchQuery : string;
                string += '&distance=' + distance;
                string += "&units=" + this.units;
                string = this.iso ? string + '&iso=' + this.iso : string;
                string = this.locationTypeId ? string + "&locationtypeid=" + this.locationTypeId : string;

                return string;
            }
        },

        getAllResultsRequestUrl: function() {
            if (this.searchQuery && this.locationTypeId) {
                return this.nearbyLocationsOfTypeWithSearchTermsUrl + this.getNearbyLocationsQueryString();
            }

            if (this.locationTypeId) {
                return this.nearbyLocationsOfTypeUrl + this.getNearbyLocationsQueryString();
            }

            if (this.searchQuery) {
                return this.nearbyLocationsWithSearchTermsUrl + this.getNearbyLocationsQueryString();
            }

            return this.nearbyLocationsUrl + this.getNearbyLocationsQueryString();
        },

        getAllResultsSuccess: function(response) {
            var decoded = JSON.parse(response.currentTarget.response);
            var json = decoded ? JSON.parse(decoded) : "";

            if (json && json.length) {
                this.model.applyNewLocations(json);
            } else if (this.distance === "none") {
                this.model.applyNewLocations(json); // don't increase radius anymore
            } else {
                this.increaseRadius(); // this will rerun location search
            }
        },

        getClosestPreferredVendorQueryString: function() {
            var string = "?latitude=" + this.latitude + "&longitude=" + this.longitude;
            string = this.searchQuery ? string + '&searchQuery=' + this.searchQuery : string;
            string += "&units=" + this.units;
            string = this.iso ? string + '&iso=' + this.iso : string;
            string = this.locationTypeId ? string + "&locationtypeid=" + this.locationTypeId : string;

            return string;
        },

        getClosestPreferredVendorRequestUrl: function() {
            if (this.searchQuery && this.locationTypeId) {
                return this.closestPreferredVendorOfTypeWithSearchTermsUrl +
                    this.getClosestPreferredVendorQueryString();
            }

            if (this.locationTypeId) {
                return this.closestPreferredVendorOfTypeUrl + this.getClosestPreferredVendorQueryString();
            }

            if (this.searchQuery) {
                return this.closestPreferredVendorWithSearchTermsUrl + this.getClosestPreferredVendorQueryString();
            }

            return this.closestPreferredVendorUrl + this.getClosestPreferredVendorQueryString();
        },

        getClosestPreferredVendorSuccess: function(response) {
            var decoded = JSON.parse(response.currentTarget.response);
            var json = decoded ? JSON.parse(decoded) : "";

            if (json) {
                this.model.applyClosestPreferredVendors([json]);
            }
        },

        getReportLocationQueryString: function(id, message) {
            return '?locationid=' + id + '&description=' + message;
        },

        getReportLocationRequestUrl: function(id, message) {
            return this.reportLocationsUrl + this.getReportLocationQueryString(id, message);
        },

        clearMapMarkers: function() {
            if (this.google.mapBounds) {
                this.google.mapBounds = new google.maps.LatLngBounds();
                $(this.google.mapMarkers).each(function(index, marker) {
                    marker.setMap(null);
                });

                this.google.mapMarkers = [];
                this.mapMarkerNumber = 1;
            }
        },

        pacSelectFirst: function(input) {
            // store the original event binding function
            var _addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent;

            function addEventListenerWrapper(type, listener) {
                // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
                // and then trigger the original listener.
                if (type === "keydown") {
                    var orig_listener = listener;
                    listener = function(event) {
                        var suggestion_selected = $(".pac-item-selected").length > 0;
                        if (event.which === 13 && !suggestion_selected) {
                            var simulated_downarrow = $.Event("keydown",
                                {
                                    keyCode: 40,
                                    which: 40
                                });
                            orig_listener.apply(input, [simulated_downarrow]);
                        }

                        orig_listener.apply(input, [event]);
                    };
                }

                _addEventListener.apply(input, [type, listener]);
            }

            input.addEventListener = addEventListenerWrapper;
            input.attachEvent = addEventListenerWrapper;
        },

        addGoogleMapMarker: function(loc) {
            var self = this;

            var icon = {
                url: loc.LocationTier === "Preferred" ? "/Include/JCI_com/Local/Images/preferred-icon.svg" : "/Include/JCI_com/Local/Images/map-pin-icon.svg",
                scaledSize: new google.maps.Size(30, 30)
            };

            var marker = new google.maps.Marker({
                position: { lat: loc.Latitude, lng: loc.Longitude },
                map: this.google.map,
                clickable: true,
                zIndex: this.zIndex++,
                optimized: false,
                //label: loc.LocationTier === "Preferred" ? "" : {text: String(this.mapMarkerNumber++), color: 'white'},  // didnt have time to add corresponding number to location list, so hiding this.
                icon: icon,
                index: loc.ID,
                locationTier: loc.LocationTier
            });

            marker.infoWindow = new google.maps.InfoWindow({ maxWidth: 200 });

            marker.addListener('click',
                function(marker, loc, mapMarkers) {
                    return function() {
                        // close all other infoWindows
                        $(mapMarkers)
                            .each(function(index, marker) {
                                marker.setIcon(icon = {
                                    url: marker.locationTier === "Preferred" ? "/Include/JCI_com/Local/Images/preferred-icon.svg" : "/Include/JCI_com/Local/Images/map-pin-icon.svg",
                                    scaledSize: new google.maps.Size(30, 30)
                                });

                                marker.infoWindow.close();
                            });

                        marker.setIcon({
                            url: loc.LocationTier === "Preferred" ? "/Include/JCI_com/Local/Images/preferred-icon-highlight.svg" : "/Include/JCI_com/Local/Images/map-pin-icon-highlight.svg",
                            scaledSize: new google.maps.Size(30, 30)
                        });

                        var target = $('.location-result-item.' + this.index);
                        if (target && target.length) {
                            var itemOffset = $('.location-result-item.' + this.index)[0].offsetTop;
                            $('.location-results-left').animate({ scrollTop: itemOffset - 10 }, 600);
                        }

                        var html = "<h4>" + loc.Name + "</h4>";
                        html += "<h5>" + loc.Address1 + "</h4>";
                        this.infoWindow.setContent(html);
                        this.infoWindow.open(self.map, marker);
                    };
                }(marker, loc, this.google.mapMarkers));

            marker.addListener('mouseover',
                function(e) {
                    self.highlightListItem(this.index);
                });

            marker.addListener('mouseout',
                function(e) {
                    self.unhighlightListItem(this.index);
                    $(self.google.mapMarkers).each(function(index, marker) {
                        marker.setIcon({
                            url: marker.locationTier === "Preferred" ? "/Include/JCI_com/Local/Images/preferred-icon.svg"
                                : "/Include/JCI_com/Local/Images/map-pin-icon.svg",
                            scaledSize: new google.maps.Size(30, 30)
                        });
                    });
                });

            this.google.mapBounds.extend(marker.getPosition());
            this.google.map.fitBounds(this.google.mapBounds);
            this.google.mapMarkers.push(marker);
            var zoom = this.google.map.getZoom();
            this.google.map.setZoom(zoom > 12 ? 12 : zoom);
        },

        highlightListItem: function(index) {
            $('.location-result-item.' + index).addClass('active');
        },

        unhighlightListItem: function(index) {
            $('.location-result-item.' + index).removeClass('active');
        },

        initAutocomplete: function() {
            var options = {
                types: ['geocode']
            };

            var input = document.getElementById(this.autocompleteId);
            this.pacSelectFirst(
                input); // selects first autocomplete element on enter. MUST be called before instantiating autocomplete
            this.google.autocomplete = input ? new google.maps.places.Autocomplete(input, options) : null;

            if (this.google.autocomplete) {
                this.google.autocomplete.addListener('place_changed', this.googleLocationChosen.bind(this));
            }
        },

        setGoogleLocation: function() {
            var geocoder = new google.maps.Geocoder();

            if (this.latitude && this.longitude && this.useGoogle) {
                var latlng = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };

                geocoder.geocode({ 'location': latlng },
                    function(results, status) {
                        if (status === 'OK') {
                            if (results[0]) {
                                this.google.autocomplete.set('place', results[0]);
                                var input = document.getElementById(this.autocompleteId);
                                $(input).val(results[0].formatted_address);
                            }
                        }
                    }.bind(this));
            } else if (this.initialAddress && this.useGoogle) {
                if (geocoder) {
                    geocoder.geocode({ 'address': this.initialAddress },
                        this.handleGooglePlaceGeocodeFromAddress.bind(this));
                }
            }
        },

        handleGooglePlaceGeocodeFromAddress: function(predictions, status) {
            if (status !== 'OK') {
                this.setLocationInputVal(this.constants.invalidAddressMessage);
                return;
            }

            if (predictions && predictions.length) {
                var result = predictions[0].geometry.location;
                this.latitude = result.lat();
                this.longitude = result.lng();

                this.setGoogleLocation();
            }
        },

        getUrlParameter: function(name) {
            name = name.toLowerCase().replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        },

        userLocationSuccess: function(position) {
            if (position && position.coords) {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.setGoogleLocation();
            }
        },

        userLocationError: function(response) {
            // do nothing
        },

        addMiscBindings: function() {
            // highlights search box
            $("input").focus(function() {
                $(this).on("click.a",
                    function(e) {
                        $(this).off("click.a").select();
                    });
            });

            if (this.useGoogle) {
                $('.geolocation-btn').click(function() {
                    navigator.geolocation.getCurrentPosition(this.userLocationSuccess.bind(this),
                        this.userLocationError.bind(this),
                        { maximumAge: 60000, timeout: 10000 });
                }.bind(this));
            }

            window.onpopstate = function(event) {
                if (event.state.path && this.latitude && this.longitude) {
                    this.extractQueryParams();
                    this.replaceHistoryState = true;
                    this.getLocationResults(this.getAllResultsRequestUrl(), this.getAllResultsSuccess);
                }
            }.bind(this);
        }
    };
})();