/*jshint esversion: 6 */
// Insights Components -------------------------------------- *

import '../Views/Feature/Insights/InsightQuilt/insightQuilt';

import '../Views/Feature/Insights/Frame/insightListFrame';

import '../Views/Feature/Insights/Coveo/CoveoEventHandling';

import '../Views/Feature/Insights/ConvoUI/convoUI';

import '../Views/Feature/Insights/SiteSearch/siteSearch';

import '../Views/Feature/Insights/InsightPanel/insightPanel.js';