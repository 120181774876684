/*jshint esversion: 6 */
$(document).ready(function () {
    $('#GatedContentModal').modal({
        backdrop: 'static',
        keyboard: false,
        focus: true,
        show: true
    });

    $(".sub-clk").click(function () {
        setTimeout(function () {
            var validationCheck = $("span").hasClass("field-validation-error");
            if (!validationCheck) {
                $("#GatedContentModal").modal('hide');
            }
        }, 500);
    });

});