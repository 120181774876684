import * as constants from './constants';

export function activateSelectedCategory(activeCategory) {
    return {
        type: constants.ACTIONS.ACTIVATE_SELECTED_CATEGORY,
        activeCategory: activeCategory
    };
}

export function activateSelectedSubcategory(activeSubcategory) {
    return {
        type: constants.ACTIONS.ACTIVATE_SELECTED_SUBCATEGORY,
        activeSubcategory: activeSubcategory
    };
}

export function deactivateSelectedSubcategory() {
    return {
        type: constants.ACTIONS.DEACTIVATE_SELECTED_SUBCATEGORY
    };
}

export function reset() {
    return {
        type: constants.ACTIONS.RESET
    };
}

export function setIsMobile(shouldSetBool) {
    return {
        type: constants.ACTIONS.SET_IS_MOBILE,
        shouldSetBool: shouldSetBool
    };
}