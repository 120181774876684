/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let NavOverflow = function (nav) {
    this.nav = nav;
    this.showMoreButton = nav.querySelector('.universalanchorbar-anchors-overflow-showmore');
    this.visibleLinks = nav.querySelector('.universalanchorbar-anchors');
    this.hiddenLinks = nav.querySelector('.universalanchorbar-anchors-overflow-links');
    this.allLinks = nav.querySelectorAll('.universalanchorbar-anchors-listitem-link');
    this.totalNumOfItems = 0;
    this.totalSpace = 0;
    this.breakWidths = [];
    this.desktopStateHasBeenSet = false;
    this.resizingUp = false;
    this.availableSpace;
    this.numOfVisibleItems;
    this.requiredSpace;
    this.prevWidth = window.innerWidth;
    this.windowEventsInitiated = false;
};

NavOverflow.prototype = {
    // Main overflow check function
    runOverflowCheck: function () {
        // If we are in desktop, run overflow checks on resize
        if (window.innerWidth > 767) {
            let _this = this;

            // Set desktop state; should only occur once but not when screen is in mobile
            if (this.desktopStateHasBeenSet === false) {
                $(this.visibleLinks).children().outerWidth(function (i, w) {
                    _this.totalSpace += w;
                    _this.totalNumOfItems += 1;
                    _this.breakWidths.push(_this.totalSpace);
                });
                this.desktopStateHasBeenSet = true;
            }
            this.numOfVisibleItems = $(this.visibleLinks).children().length;

            if (this.numOfVisibleItems === this.totalNumOfItems
                || this.numOfVisibleItems === this.totalNumOfItems - 1 && this.resizingUp) {
                this.availableSpace = $(this.nav).width() + 40;
            } else {
                this.availableSpace = $(this.nav).width() - 60;
            }

            this.requiredSpace = this.breakWidths[this.numOfVisibleItems - 1];

            if (this.requiredSpace > this.availableSpace) {
                // There is not enough space
                $(this.visibleLinks).children().last().prependTo(this.hiddenLinks);
                this.numOfVisibleItems -= 1;
                this.runOverflowCheck();
            } else if (this.availableSpace > this.breakWidths[this.numOfVisibleItems]) {
                // There is enough space
                $(this.hiddenLinks).children().first().appendTo(this.visibleLinks);
                this.numOfVisibleItems += 1;
            }
            // Update the button accordingly
            if (this.numOfVisibleItems !== this.totalNumOfItems) {
                this.showMoreButton.classList.add('overflowButtonIsVisible');
            } else this.showMoreButton.classList.remove('overflowButtonIsVisible');
        }

        // If we haven't yet, init UI events
        if (this.windowEventsInitiated === false) {
            this.initResizeFunctionality();
            this.initClickFunctionality();
            this.initScrollFunctionality();
            this.windowEventsInitiated = true;
        }
    },

    // Resize events
    initResizeFunctionality: function () {
        let _this = this;
        window.addEventListener('resize', function () {
            // If we are moving from desktop to mobile, need to replace all hidden children from desktop
            if (window.innerWidth <= 767 && _this.prevWidth > 767) {
                $(_this.hiddenLinks).children().appendTo(_this.visibleLinks);
            }

            if (_this.prevWidth < window.innerWidth) {
                _this.resizingUp = true;
            } else {
                _this.resizingUp = false;
            }

            // Run overflow checks on resize
            _this.runOverflowCheck();

            // Update previous width to current width for next resize event
            _this.prevWidth = window.innerWidth;
        });
    },

    // Click events
    initClickFunctionality: function () {
        let _this = this;
        this.showMoreButton.addEventListener('click', function () {
            $(_this.hiddenLinks).toggleClass('showOverflowLinks');
            $(this).toggleClass('overflowLinksAreVisible');
        });

        this.allLinks.forEach(function (link) {
            link.addEventListener('click', function () {
                _this.hideOverflowLinks();
            });
        });
    },

    // Scroll events
    initScrollFunctionality: function () {
        let _this = this;
        window.addEventListener('scroll', function () {
            if (window.innerWidth > 767 && _this.showMoreButton.classList.contains('overflowLinksAreVisible')) {
                _this.hideOverflowLinks();
            }
        });
    },

    // Method for checking if hidden links are visible, and if so then hiding them
    hideOverflowLinks: function () {
        if (window.innerWidth > 767 && this.hiddenLinks.classList.contains('showOverflowLinks') && this.showMoreButton.classList.contains('overflowLinksAreVisible')) {
            // If desktop && if link overflow is visible, hide overflow after user selects an anchor and page scrolls
            this.hiddenLinks.classList.remove('showOverflowLinks');
            this.showMoreButton.classList.remove('overflowLinksAreVisible');
        }
    }
};

export { NavOverflow };