/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';

let TechnicalSupportPanel = function (technicalSupportPanel) {
    this.technicalSupportPanel = technicalSupportPanel;
    this.items = this.technicalSupportPanel.querySelectorAll('.universaltechnicalsupportpanel-item');
};

TechnicalSupportPanel.prototype = {
    init: function () {
        let _this = this;
        this.iterateItems(_this);
    },

    iterateItems: function (_this) {
        if (_this && _this.items) {
            _this.items.forEach(function (item) {
                // Get item elements
                let button = item.querySelector('.universaltechnicalsupportpanel-item-button');
                let dropdownToToggle = item.querySelector('.universallinklist');

                // Init click events for button
                _this.attachButtonClickEvents(button, _this, dropdownToToggle, item);
            });
        }
    },

    attachButtonClickEvents: function (button, _this, dropdownToToggle, item) {
        if (button && _this && dropdownToToggle && item) {
            button.addEventListener('click', function (event) {
                _this.closeOpenDropdowns(_this, event);
                _this.toggleDropdown(dropdownToToggle, item);
            });
        }
    },

    toggleDropdown: function (dropdownToToggle, item) {
        if (dropdownToToggle && item) {
            item.classList.toggle('dropdownIsOpen');
        }
    },

    closeOpenDropdowns: function (_this, event) {
        if (_this && event) {
            let allActiveItems = _this.technicalSupportPanel.querySelectorAll('.universaltechnicalsupportpanel-item.dropdownIsOpen');

            let targetedItem = event.target.closest('.universaltechnicalsupportpanel-item');

            if (allActiveItems && targetedItem) {
                allActiveItems.forEach(function (item) {
                    if (item !== targetedItem) {
                        item.classList.remove('dropdownIsOpen');
                    }
                });
            }
        }
    }
};

window.addEventListener('load', function () {
    let technicalSupportPanels = document.querySelectorAll('.universaltechnicalsupportpanel');
    if (technicalSupportPanels) {
        technicalSupportPanels.forEach(function (technicalSupportPanel) {
            // Construct new Technical Support Panel
            let newTechnicalSupportPanel = new TechnicalSupportPanel(technicalSupportPanel);
            newTechnicalSupportPanel.init();
        });
    }
});