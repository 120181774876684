/*jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../appState/actions';
import ConvoUIPromptDefault from './prompt-states/prompt-default';
import ConvoUIPromptResting from './prompt-states/prompt-resting';
import * as services from '../appState/services';
import * as constants from '../appState/constants';

class ConvoUIPromptContainer extends React.Component {
    constructor(props) {
        super(props);

        // Don't store in local state, b/c we do not want a re-render if this is updated
        this.checkInTimeout = null;

        // Bind Statements
        this.navigateToAssistant = this.navigateToAssistant.bind(this);
        this.navigateToContact = this.navigateToContact.bind(this);
        this.declineAssistance = this.declineAssistance.bind(this);
        this.declineCheckIn = this.declineCheckIn.bind(this);
    }

    navigateToAssistant() {
        if (this.props.globalState.shouldSearchForRecommendations()) {
            this.props.actions.getRecommendationsFromSearch();
        }
        this.props.actions.navigateToAssistantPanel();

        let state = this.props.globalState.prompt_state.Eyebrow === undefined ? "Resting State" : "Initial State";
        promptButtonStateAction(state, this.props.globalState.prompt_state.AcceptButtonText);
    }

    navigateToContact() {
        this.props.actions.navigateToContactPanel();
    }

    declineAssistance() {
        this.props.actions.declineAssistance();
        promptButtonStateAction("Initial State", this.props.globalState.prompt_state.DeclineButtonText);
    }

    declineCheckIn() {
        this.props.actions.declineCheckIn();
    }
    
    checkIn() {
        let _this = this;
        let minutesToWait = this.props.globalState.prompt_state.MinutesBeforeCheckIn;
        let millisecondsToWait = minutesToWait * 60000;

        this.checkInTimeout = setTimeout(function () {
            _this.props.actions.checkIn();
        }, millisecondsToWait);
    }

    disableCheckIn() {
        if (this.checkInTimeout !== null) {
            clearInterval(this.checkInTimeout);
            this.checkInTimeout = null;
        }
    }

    render() {
        if (this.props.globalState.checkingIn) {
            return (
                <ConvoUIPromptDefault
                    data={this.props.globalState.prompt_state}
                    acceptAssistanceHandler={this.navigateToContact}
                    declineAssistanceHandler={this.declineCheckIn}
                />
            );
        }

        else if (this.props.globalState.hasDeclinedCheckIn) {
            return (
                <ConvoUIPromptResting
                    data={this.props.globalState.prompt_state}
                    navigateToAssistantHandler={this.navigateToAssistant}
                />
            );
        }

        else if (this.props.globalState.hasDeclined) {
            return (
                <ConvoUIPromptResting
                    data={this.props.globalState.prompt_state}
                    navigateToAssistantHandler={this.navigateToAssistant}
                    onLoad={this.checkIn()}
                />
            );
        }

        else if (!this.props.globalState.showSidebar) {
            return (
                <ConvoUIPromptDefault
                    data={this.props.globalState.prompt_state}
                    acceptAssistanceHandler={this.navigateToAssistant}
                    declineAssistanceHandler={this.declineAssistance}
                    onLoad={this.checkIn()}
                />
            );
        }

        else if (this.props.globalState.showSidebar) {
            this.disableCheckIn();
            return null;
        }

        else return null;
    }
}

function promptButtonStateAction(state, buttonText) {
    UniversalAnalytics.SendEvent('Convo UI', 'Prompt Data [ ' + state + ' ] [Button (' + buttonText + ')]', 'Prompt Data [ ' + state + ' ] [Button (' + buttonText + ')]', 10);
}


function mapStateToProps(state) {
    return {
        globalState: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvoUIPromptContainer);