/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let ScrollDownIndicator = function (scrollDownIndicator) {
    this.scrollDownIndicator = scrollDownIndicator;
};

ScrollDownIndicator.prototype = {
    init: function () {
        let _this = this;

        if (_this && _this.scrollDownIndicator) {
            _this.setPosition(_this);
            _this.listenForScroll(_this);
            _this.listenForClick(_this);
            _this.setColor(_this);
        }
    },

    listenForScroll: function (_this) {
        $(window).scroll(function () {
            _this.setPosition(_this);
        });
    },

    setPosition: function (_this) {
        if ($(window).scrollTop() <= 0) {
            _this.scrollDownIndicator.classList.add('show');
        } else {
            _this.scrollDownIndicator.classList.remove('show');
        }
    },

    listenForClick: function (_this) {
        $(_this.scrollDownIndicator).click(function () {
            let viewportheight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            $('html, body').animate({ scrollTop: viewportheight }, 1000);
            return false;
        });
    },

    setColor: function (_this) {

        var a = _this.scrollDownIndicator.querySelectorAll('div > object')[0];
        var svgDoc = a.contentDocument;
        var paths = svgDoc.getElementsByTagName("path");
        if (paths.length > 0) {
            paths[paths.length - 1].setAttribute("fill", _this.scrollDownIndicator.dataset.color);
        }
    }
};

window.addEventListener('load', function () {
    let scrollDownIndicators = document.querySelectorAll('.scroll-down-indicator');
    if (scrollDownIndicators) {
        scrollDownIndicators.forEach(function (scrollDownIndicator) {
            var newScrollDownIndicator = new ScrollDownIndicator(scrollDownIndicator);
            newScrollDownIndicator.init();
        });
    }
});