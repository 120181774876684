imagesLoaded.makeJQueryPlugin($);

window.addEventListener('load', function () {
    var $grid = $(".CoveoSearchInterface:not(.site-search)");

    if ($grid.length > 0) {
        $grid.on(Coveo.ResultListEvents.newResultsDisplayed,
            function () {
                var $container = $grid.find('.coveo-result-list-container');

                if ($container.length > 0) {
                    $container.masonry({
                        columnWidth: '.grid-sizer',
                        itemSelector: '.grid-item',
                        percentPosition: true,
                        transitionDuration: 0
                    });
                    $container.masonry('reloadItems');
                    $container.masonry('layout');

                    $container.imagesLoaded(function () {
                        $container.masonry({
                            columnWidth: '.grid-sizer',
                            itemSelector: '.grid-item',
                            percentPosition: true,
                            transitionDuration: 0
                        });
                        //$grid.masonry('reloadItems');
                        //$grid.masonry('layout');
                    });
                }
            });
    }

});
Coveo.TemplateHelpers.registerTemplateHelper("languageUrlCleanup", (value, options) => {
    // This method is to clean up language URLs so they point to the current language's version, only
    if (typeof (value) !== "undefined") {
        if (window.CurrentLanguageIso) {
            value = value.replace("{lang_lang}/", window.CurrentLanguageIso);
        }
    }
    return value;
});
