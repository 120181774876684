/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';

let UtilityBrandBar = function (utilityBrandBar) {
    this.utilityBrandBar = utilityBrandBar;
    this.countrySearchInput = this.utilityBrandBar.querySelector('.country-search-input');
    this.countrySearchResultsList = this.utilityBrandBar.querySelector('.country-search-results-list');
    this.countrySearchShowAll = this.utilityBrandBar.querySelector('.country-search-showall');
    this.allCountriesModalContent = this.utilityBrandBar.querySelector('.utility-wrap');
    this.mobileCountryAccordionButton = this.allCountriesModalContent.querySelector('.accordionItemHeading');
    this.countriesElements = this.utilityBrandBar.querySelectorAll('.country-list a');
    this.countriesArray = [];
};

UtilityBrandBar.prototype.Init = function () {
    var _this = this;

    if (_this.mobileCountryAccordionButton) {
        _this.mobileCountryAccordionButton.addEventListener('click', function () {
            _this.AddFocusToCountrySearchInput(_this);
        });
    }

    if (_this.countrySearchInput) {
        _this.CreateCountriesArray(_this);

        _this.countrySearchInput.addEventListener('keyup', function (e) {
            e.stopImmediatePropagation();

            _this.ClearResultsList(_this);

            let results = _this.SearchForCountry(_this, e.target.value);

            results.forEach(function (result) {
                _this.ShowCountryInResults(_this, result, e.target.value);
            });
        });

        _this.countrySearchInput.addEventListener('search', function () {
            // Fires when user clears search bar input
            _this.ClearResultsList(_this);
        });
    }

    if (_this.countrySearchShowAll) {
        _this.countrySearchShowAll.addEventListener('click', function () {
            _this.ShowAllCountriesModal(_this);
        });
    }
};

UtilityBrandBar.prototype.AddFocusToCountrySearchInput = function (_this) {
    _this.countrySearchInput.focus();
};

UtilityBrandBar.prototype.CreateCountriesArray = function (_this) {
    _this.countriesElements.forEach(function (country) {
        let countryItem = [country.innerText, country.getAttribute('href')];
        _this.countriesArray.push(countryItem);
    });
};

UtilityBrandBar.prototype.SearchForCountry = function (_this, inputValue) {
    if (inputValue !== '') {
        return _this.countriesArray.filter(country => country[0].toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
    } else {
        // If inputValue is empty, return empty array
        return [];
    }
};

UtilityBrandBar.prototype.ShowCountryInResults = function (_this, result, inputValue) {
    // Create new elements
    let docFrag = document.createDocumentFragment();
    let listItem = document.createElement('li');
    let anchor = document.createElement('a');

    // Bold piece of text that user searched for
    var regEx = new RegExp(inputValue, "gi");
    let highlightedTerm = result[0].replace(regEx, `<span class='country-search-term'>$&</span>`);

    // Set anchor properties
    anchor.innerHTML = highlightedTerm;
    anchor.setAttribute('href', result[1]);

    // Append elements to document fragment, then document
    listItem.append(anchor);
    docFrag.append(listItem);
    _this.countrySearchResultsList.append(docFrag);
};

UtilityBrandBar.prototype.ClearResultsList = function (_this) {
    while (_this.countrySearchResultsList.firstChild) {
        _this.countrySearchResultsList.removeChild(_this.countrySearchResultsList.firstChild);
    }
};

UtilityBrandBar.prototype.ShowAllCountriesModal = function (_this) {
    // Add modal to document - need to 'unbury' the element from its ancestors
    // Create elements
    let docFrag = document.createDocumentFragment();
    let closeButton = document.createElement('button');
    let div = document.createElement('div');

    // Add classes, content
    closeButton.classList.add('country-search-showall-modal-close');
    div.classList.add('utilitybrandbar');
    div.classList.add('country-search-showall-modal');
    div.innerHTML = _this.allCountriesModalContent.innerHTML;
    div.addEventListener('click', function (e) {
        // Prevents nav underneath from receiving click events
        e.stopPropagation();
    });

    // Append
    div.prepend(closeButton);
    docFrag.append(div);
    document.body.append(docFrag);

    // Add modal event listeners (cannot add on init, as they won't exist at that time)
    _this.ToggleAccordionsOnClick();
    closeButton.addEventListener('click', function (e) {
        e.stopImmediatePropagation();
        _this.CloseCountryModal(div);
    });
};

UtilityBrandBar.prototype.ToggleAccordionsOnClick = function () {
    let accordions = document.querySelectorAll('.country-search-showall-modal .subAccordionItem');
    accordions.forEach(function (accordion) {
        accordion.addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            accordion.classList.toggle('close');
            accordion.classList.toggle('open');
        });
    });
};

UtilityBrandBar.prototype.CloseCountryModal = function (modal) {
    modal.remove();
};

window.addEventListener('load', function () {
    let allUtilityBrandBars = document.querySelectorAll('.utilitybrandbar');
    if (allUtilityBrandBars) {
        allUtilityBrandBars.forEach(function (utilityBrandBar) {
            let utilBrandBar = new UtilityBrandBar(utilityBrandBar);
            utilBrandBar.Init();
        });
    }
});