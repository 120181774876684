/*jshint esversion: 6 */

(function () {
    window.jci = window.jci || {};
    //var $ = jQuery;

    window.jci.bing = function(apiKey) {
        this.key = apiKey;

        this.getAddressFromLatLng = function(lat, lng, uiHandler) {
            $.ajax({
                url: this.getAddressFromLatLngUrl(lat, lng),
                dataType: 'jsonp',
                jsonp: 'jsonp',
                success: function(response) {
                    if (response.resourceSets && response.resourceSets.length) {
                        if (response.resourceSets[0].resources && response.resourceSets[0].resources.length) {
                            uiHandler(response.resourceSets[0].resources[0].name);
                        }
                    }
                },
                error: function(e, i, o) {
                }
            });
        };

        this.getAddressFromLatLngUrl = function(lat, lng) {
            return 'http://dev.virtualearth.net/REST/v1/Locations/' +
                lat +
                ',' +
                lng +
                '?o=json&key=' +
                this.key;
        };

        this.getLocationFromAddress = function(address, successCallback) {
            var url = this.getLocationFromAddressUrl(address);

            $.ajax({
                url: url,
                dataType: 'jsonp',
                jsonp: 'jsonp',
                success: successCallback,
                error: function(e) {
                }
            });
        };

        this.hasCoordinate = function(bingResponse) {
            if (bingResponse.resourceSets && bingResponse.resourceSets.length) {
                if (bingResponse.resourceSets[0].resources && bingResponse.resourceSets[0].resources.length) {
                    if (bingResponse.resourceSets[0].resources[0].point.coordinates &&
                        bingResponse.resourceSets[0].resources[0].point.coordinates.length === 2) {
                        return true;
                    }
                }
            }
        };

        this.getLocationFromAddressUrl = function(query) {
            return 'http://dev.virtualearth.net/REST/v1/Locations?q=' +
                encodeURIComponent(query) +
                '&key=' +
                this.key;
        };
    };
})();