/*jshint esversion: 6 */

// polyfills, etc
require('babel-polyfill');
require('es6-promise').polyfill();
require('knockout');


// BOOTSTRAP ========================================================= *
import 'bootstrap';
import './src/bootstrap-setup.js';
import 'bootstrap-select';
import 'bootstrap-validator';

// FOUNDATION ========================================================= *
// Common/Universal
import '../../../../../../../../Foundation/Common/code/Scripts/Common/common';
// Multisite
import '../../../../../../../../Foundation/Multisite/code/Scripts/Multisite';

// FEATURE ========================================================= *
// Components
import '../../../../../../../../Feature/Components/code/Scripts/Components';

// Events
import '../../../../../../../../Feature/Events/code/Scripts/Events';

// Forms
import '../../../../../../../../Feature/Forms/code/Scripts/Forms';

// Navigation
import '../../../../../../../../Feature/Navigation/code/Scripts/Navigation';

// Locations
import '../../../../../../../../Feature/Locations/code/Scripts/Locations';

// Insights
import '../../../../../../../../Feature/Insights/code/Scripts/Insights';

// My Profile
import '../../../../../../../../Feature/MyProfile/code/Scripts/MyProfile';