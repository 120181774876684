/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

window.addEventListener('load', function () {
    var elem = document.querySelector('.insightpanel .container .grid');
    if (elem) {
        var msnry = new Masonry(elem, {
            // options
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            percentPosition: true,
            horizontalOrder: true
        });
    }
});