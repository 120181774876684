/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';

let SolrSearch = function (solrSearchComponent) {
    this.solrSearchComponent = solrSearchComponent;
    this.searchButton = this.solrSearchComponent.querySelector('.initiate-search');
    this.searchInput = this.solrSearchComponent.querySelector('.universalheadersearchoverlay-searchbar-input');
};
SolrSearch.prototype.Init = function () {
    var _this = this;

    this.searchButton.addEventListener('click', function () {
        _this.Search();
    });
};
SolrSearch.prototype.Search = function () {
    var searchUrl = this.searchInput.getAttribute('data-url');
    var searchTerm = this.searchInput.value;
    window.location = searchUrl + "?searchString=" + searchTerm;
};

window.addEventListener('load', function () {
    let allSearchBars = document.querySelectorAll('.universalheadersearchoverlay-searchbar-solr');
    if (allSearchBars) {
        allSearchBars.forEach(function (solrSearchComponent) {
            var solrSearch = new SolrSearch(solrSearchComponent);
            solrSearch.Init();
        });
    }
});