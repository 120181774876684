/*jshint esversion: 6 */

import React from 'react';
import ConvoUIContentItem from '../shared/contentitem';

const ConvoUIRecentPanelPopulated = (props) => {
    return (
        <ul className="universalconvoui-contentitem-list">
            {
                props.savedRecommendations.map((item, index) => {
                    return (
                        <ConvoUIContentItem
                            key={index}
                            data={item}
                            closeSidebarHandler={props.closeSidebarHandler}
                        />
                    );
                })
            }
        </ul>
    );
};

export default ConvoUIRecentPanelPopulated;