/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';

var GlobalAlert = function (globalAlertComponent) {
    this.globalAlertComponent = globalAlertComponent;
    this.closeButton = this.globalAlertComponent.querySelector('.universalglobalalert-close');
};
GlobalAlert.prototype.Init = function () {
    var _this = this;

    this.closeButton.addEventListener('click', function () {
        _this.Close();
    });
};
GlobalAlert.prototype.Close = function () {
    jQuery.post("/universalapi/Feature/Navigation/UniversalGlobalAlert/CloseGlobalAlertMessage");
    this.globalAlertComponent.style.display = "none";
};

GlobalAlert.Activate = function() {
    let allGlobalAlerts = document.querySelectorAll('.universalglobalalert');
    if (allGlobalAlerts) {
        allGlobalAlerts.forEach(function (globalAlertComponent) {
            // Construct new FeaturedData
            var globalAlert = new GlobalAlert(globalAlertComponent);

            // Init functionality on load
            globalAlert.Init();
        });
    }
}

GlobalAlert.TryLoad = function () {
    jQuery.get("/universalapi/Feature/Navigation/UniversalGlobalAlert/GetGlobalAlertMessage", function (data) {
        jQuery(".universalheader").after(data);
        GlobalAlert.Activate();
    });
}

window.GlobalAlert = GlobalAlert;