import React from 'react';
import ConvoUIAnswerOption from './answeroption';

const ConvoUIQuestion = (props) => {
    return (
        <div className='universalconvoui-question'>
            {
                props.questionObject.QuestionText &&
                <p className='universalconvoui-question-questiontext'>{props.questionObject.QuestionText}</p>
            }
            <div className='universalconvoui-question-box'>
                {
                    props.questionObject.QuestionSpecialInstructions &&
                    <small className='universalconvoui-question-instructions'>
                        {props.questionObject.QuestionSpecialInstructions}
                    </small>
                }
                {
                    props.questionObject.QuestionOptions &&
                    <ul className='universalconvoui-question-answeroptions'>
                        {
                            Object.keys(props.questionObject.QuestionOptions).map((optionKey, index) => {
                                let optionIndex = props.questionIndex + '-optionIndex-' + index;
                                if (optionKey) {
                                    return (
                                        <ConvoUIAnswerOption
                                            key={index}
                                            questionIndex={props.questionIndex}
                                            optionIndex={optionIndex}
                                            optionText={optionKey}
                                            inputChangeHandler={() => {
                                                props.inputChangeHandler(props.questionObject.QuestionName, { answerText: optionKey, answerGuid: props.questionObject.QuestionOptions[optionKey] });
                                            }}
                                        />
                                    );
                                }
                                else return null;
                            })
                        }
                        {
                            // Option for 'none' if not first question and if 'none' option text exists
                            !props.isFirstQuestion && props.questionObject.QuestionNoneOptionText &&
                            <ConvoUIAnswerOption
                                questionIndex={props.questionIndex}
                                optionIndex={props.questionIndex + '-optionIndex-' + (props.questionObject.QuestionOptions.length + 1)}
                                optionText={props.questionObject.QuestionNoneOptionText}
                                inputChangeHandler={() => {
                                    props.inputChangeHandler(props.questionObject.QuestionName, { answerText: props.noIndustryText, answerGuid: null });
                                }}
                            />
                        }
                    </ul>
                }
            </div>
        </div>
    );
};

export default ConvoUIQuestion;