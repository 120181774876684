import * as constants from './constants';
import * as services from './services';

var infoData = {};
var infoLanguage = {};
if (constants.INFOGRAPHICS_REACTROOT) {
    infoData = constants.INFOGRAPHICS_REACTROOT.getAttribute('data-modelItemId');
    infoLanguage = constants.INFOGRAPHICS_REACTROOT.getAttribute('data-modellanguage');
}

// Get Data
let sitecoreData = services.getData(infoData, infoLanguage);

// Local Storage
let initialState = {

    // Data from Sitecore
    sitecoreData: sitecoreData,
    contextData: sitecoreData, // Data to use in current context level
    actionIndicatorCustomIconUrl: sitecoreData.ActionIndicatorCustomIconUrl,
    backLinkText: sitecoreData.BackLinkText,
    hideSideNav: sitecoreData.HideSidenav,

    // State
    isMobile: false,
    currentLevel: constants.LEVEL_ENUM.ONE,
    activeSubcategoryData: null
};

// Reducer
export default function infographicsReducer(state = initialState, action) {
    let updatedState = state;

    if (action.type === constants.ACTIONS.ACTIVATE_SELECTED_CATEGORY) {
        updatedState = Object.assign({},
            state, {
                currentLevel: constants.LEVEL_ENUM.TWO,
                contextData: action.activeCategory
            });
    }

    else if (action.type === constants.ACTIONS.ACTIVATE_SELECTED_SUBCATEGORY) {
        updatedState = Object.assign({},
            state, {
                activeSubcategoryData: action.activeSubcategory
            });
    }

    else if (action.type === constants.ACTIONS.DEACTIVATE_SELECTED_SUBCATEGORY) {
        updatedState = Object.assign({},
            state, {
                activeSubcategoryData: null
            });
    }

    else if (action.type === constants.ACTIONS.RESET) {
        updatedState = Object.assign({},
            state, initialState);
        updatedState.isMobile = state.isMobile;
    }

    else if (action.type === constants.ACTIONS.SET_IS_MOBILE) {
        updatedState = Object.assign({},
            state, {
                isMobile: action.shouldSetBool
            });
    }

    return updatedState;
}