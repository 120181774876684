/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

window.requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

let CampaignHeader = function (campaignHeader) {
    this.campaignHeader = campaignHeader;
    this.anchorButton = this.campaignHeader.querySelector('.campaignheader-cta-anchortoform');
    this.input = this.campaignHeader.querySelector('.campaignheader-cta-input');
};

CampaignHeader.prototype = {
    scrollToY: function (o, n) {
        function a() { t += 1 / 60; var n = t / c, l = i(n); 1 > n ? (requestAnimFrame(a), window.scrollTo(0, r + (o - r) * l)) : window.scrollTo(0, o) } var r = window.scrollY, o = o || 0, n = n || 2e3, t = 0, c = Math.max(.1, Math.min(Math.abs(r - o) / n, .8)), i = function (o) { return -.5 * (Math.cos(Math.PI * o) - 1) }; a()
    },
    init: function () {
        if (this.anchorButton === null) {
            return;
        }

        let _this = this;

        _this.anchorButton.addEventListener('click', function (evt) {
            var inputTargetID = evt.currentTarget.getAttribute("data-anchortarget");
            if (inputTargetID && inputTargetID !== "") {
                var scrollTarget = null;
                if (inputTargetID.indexOf("#") < 0) { inputTargetID = "#" + inputTargetID; }
                var inputTarget = document.querySelector(inputTargetID);
                if (inputTarget !== null && (inputTarget.nodeName.toLowerCase() === "input" || inputTarget.nodeName.toLowerCase() === "select")) {
                    if (_this.input !== null && _this.input.value !== "") {
                        // If input, send along data in event
                        inputTarget.value = _this.input.value;
                    } 
                    // Scrolls to form wrapping the input target
                    scrollTarget = inputTarget.form;
                } else if (inputTarget !== null) {
                    // If this is just an anchor, target that item
                    scrollTarget = inputTarget;
                }
                if (scrollTarget !== null) {
                    // Need to scroll to form if there is one
                    var pos = scrollTarget.offsetTop;
                    //window.scrollTo({ top: pos, left: 0, behavior: 'smooth' });
                    _this.scrollToY(pos, 1000);
                }
            }
        });

    }
};

let campaignHeaders = document.querySelectorAll('.campaignheader');
if (campaignHeaders) {
    // Construct new Campaign Headers
    campaignHeaders.forEach(function (campaignHeader) {
        let ch = new CampaignHeader(campaignHeader);
        ch.init();
    });
}

