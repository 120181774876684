//STORAGE VARIABLES
export const CONVO_UI_STATE = 'CONVO_UI_STATE';
export const CONVO_UI_SESSION = 'CONVO_UI_SESSION';

//DOM ELEMENTS
export const SIDEBAR_ELEMENT = document.querySelector('.universalconvoui-sidebar');
export const CONTACT_ELEMENT = document.querySelector('.universalconvoui-contact');

//TOKENS
export const TOKEN_SELECTED_CATEGORY = '{SELECTED_CATEGORY}';

//DOM REACTROOTS
export const PROMPT_CONTAINER_REACTROOT = document.querySelector('.universalconvoui-prompt-reactroot');
export const CLOSE_REACTROOT = document.querySelector('.universalconvoui-sidebar-close-reactroot');
export const NAV_REACTROOT = document.querySelector('.universalconvoui-nav-reactroot');
export const PANEL_CONTAINER_REACTROOT = document.querySelector('.universalconvoui-sidebar-panels-reactroot');

//ACTION TYPES
export const DECLINE_ASSISTANCE = 'DECLINE_ASSISTANCE';
export const DECLINE_CHECK_IN = 'DECLINE_CHECK_IN';
export const ENGAGE_ASSISTANT_WORKFLOW = 'ENGAGE_ASSISTANT_WORKFLOW';
export const NAV_TO_ASSISTANT = 'NAV_TO_ASSISTANT';
export const NAV_TO_RECENT = 'NAV_TO_RECENT';
export const NAV_TO_CONTACT = 'NAV_TO_CONTACT';
export const PROVIDE_MAPPED_RECOMMENDATIONS = 'PROVIDE_MAPPED_RECOMMENDATIONS';
export const PROVIDE_SEARCH_RECOMMENDATIONS = 'PROVIDE_SEARCH_RECOMMENDATIONS';
export const RESET_RECOMMENDATIONS = 'RESET_RECOMMENDATIONS';
export const SAVE_ANSWER = 'SAVE_ANSWER';
export const CHECK_IN = 'CHECK_IN';

//STRINGS
export const INDUSTRY_SELECTION_NONE = 'none';
export const CATEGORY = 'category';
export const INDUSTRY = 'industry';