/*jshint esversion: 6 */

import React from 'react';
import ConvoUIContentItem from '../shared/contentitem';
import ConvoUIReset from '../shared/reset';

const ConvoUIAssistantPanelRecommendations = (props) => {
    return (
        <div className="universalconvoui-assistant">
            {
                props.AssistantWatermark &&
                <p className="universalconvoui-assistant-watermark">
                    {props.AssistantWatermark}
                </p>
            }
            {
                props.data.Text &&
                <p className="universalconvoui-assistant-text">
                    {props.data.Text}
                </p>
            }
            {
                props.recommendations.length > 0 &&
                <ul className="universalconvoui-contentitem-list">
                    {
                        props.recommendations.map((recommendation, index) => {
                            return <ConvoUIContentItem key={index} data={recommendation} closeSidebarHandler={props.closeSidebarHandler} />;
                        })
                    }
                </ul>
            }             

            <ConvoUIReset
                resetRecommendationsHandler={props.resetRecommendationsHandler}
                resetButtonText={props.resetButtonText}
            />
        </div>
    );
};

export default ConvoUIAssistantPanelRecommendations;