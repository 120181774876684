import React from 'react';

const ConvoUIAnswerOption = (props) => {
    return (
        <li className='universalconvoui-question-answeroptions-item'>

            <input
                className='universalconvoui-question-answeroptions-item-input'
                id={props.optionIndex}
                type='radio'
                name={props.questionIndex}
                onChange={props.inputChangeHandler}
            />

            <label
                className='universalconvoui-question-answeroptions-item-label'
                htmlFor={props.optionIndex}
            >
                {props.optionText}
            </label>

        </li>
    );
};

export default ConvoUIAnswerOption;