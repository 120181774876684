
let UniversalCascadingSelectList = function (selectListComponent) {
    this.selectListComponent = selectListComponent;
    this.parentSelectList = this.selectListComponent.querySelector('.universalcascadingselectlist-ddn');
    this.childSelectList = this.selectListComponent.querySelector('.universalcascadingselectlist-child-ddn');
    this.textField = this.childSelectList.getAttribute("data-sc-text");
    this.valueField = this.childSelectList.getAttribute("data-sc-value");
};
UniversalCascadingSelectList.prototype.Init = function () {
    var _this = this;

    _this.PopulateChildList();

    this.parentSelectList.addEventListener('change', function () {
        _this.PopulateChildList();
    });
};

UniversalCascadingSelectList.prototype.PopulateChildList = function () {
    var childSelectList = this.childSelectList;

    //get the value from select list 1
    var itemId = this.parentSelectList.value;

    //pass it via the get, as querystring parameter
    $.get("/universalapi/Feature/Forms/CascadeDropdownList/SelectItems", { itemId: itemId, textFieldName: this.textField, valueFieldName: this.valueField }, function (data, status) {

        //clear and load SelectList2
        childSelectList.innerHTML = "";
        var host = document.domain.toLowerCase();
        var isSenso = host.includes('sensormatic.');
        if (status === "success") {
            if (!isSenso) {
                $.each(data, function (key, entry) {
                    var addedOption = document.createElement("option");
                    addedOption.text = entry.Text;
                    addedOption.value = entry.Value;

                    childSelectList.add(addedOption, 1);
                });
            }
            else {
                if (data.length > 0 && $.isArray(data)) {
                    $('.child-ddl').show();
                    $.each(data, function (key, entry) {
                        var addedOption = document.createElement("option");
                        addedOption.text = entry.Text;
                        addedOption.value = entry.Value;
                        childSelectList.add(addedOption, 1);
                    });
                    var select = $(childSelectList);
                    select.html(select.find('option').sort(function (x, y) {
                        // to change to descending order switch "<" for ">"
                        return $(x).text() > $(y).text() ? 1 : -1;
                    }));
                    $(".universalcascadingselectlist-child-ddn").val($(".universalcascadingselectlist-child-ddn option:first").val());
                }
                else {
                    $('.child-ddl').hide();
                }
            }
        }
    });
};

window.addEventListener('load', function () {
    let selectLists = document.querySelectorAll('.universalcascadingselectlist');

    if (selectLists) {
        selectLists.forEach(function (selectListComponent) {
            // Construct new FeaturedData
            var selectList = new UniversalCascadingSelectList(selectListComponent);

            // Init functionality on load
            selectList.Init();
        });
    }
});
