/*jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import smoothscroll from 'smoothscroll-polyfill';
import * as appActions from '../appState/actions';
import * as constants from '../appState/constants';
import SideNav from './sidenav/sidenav';
import ImageMap from './imagemap/imagemap';

class InfographicsContainer extends React.Component {
    constructor(props) {
        super(props);

        // Bind Statements
        this.reset = this.reset.bind(this);
        this.setIsMobile = this.setIsMobile.bind(this);
        this.handleNavItemClick = this.handleNavItemClick.bind(this);
        this.handleImageMapClick = this.handleImageMapClick.bind(this);
        this.slideMobileSidenav = this.slideMobileSidenav.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        var parentElement = document.querySelector(".infographics-sidenav");
        var childElement = document.querySelector(".navitem-active");

        if (childElement !== null) {
            parentElement.scrollTo(0, childElement.offsetTop);
        }
    }

    // REACT LIFECYCLE EVENTS
    componentDidMount() {
        smoothscroll.polyfill();
        this.setIsMobile();
        window.addEventListener('resize', this.setIsMobile);
        this.resetZoom();
    }

    // REDUX ACTIONS
    activateSelectedCategory(activeCategory) {
        this.props.actions.activateSelectedCategory(activeCategory);
        this.resetZoom();
        this.scrollToTopOfContainer();
    }

    activateSelectedSubcategory(activeSubcategory) {
        this.props.actions.activateSelectedSubcategory(activeSubcategory);
    }

    deactivateSelectedSubcategory() {
        this.props.actions.deactivateSelectedSubcategory();
        this.resetMobileSidenav();
    }

    reset() {
        this.props.actions.reset();
        this.resetZoom();
        this.resetMobileSidenav();
        this.scrollToTopOfContainer();
    }

    setIsMobile() {
        let windowWidth = window.innerWidth;
        let shouldRenderMobile = windowWidth <= 1024;
        let currentlyRenderingMobile = this.props.reduxGlobalState.isMobile;

        if (shouldRenderMobile && !currentlyRenderingMobile) {
            this.props.actions.setIsMobile(true);
        } else if (!shouldRenderMobile && currentlyRenderingMobile) {
            this.props.actions.setIsMobile(false);
            this.resetMobileSidenav();
        }
    }

    // LOCAL HELPERS
    isInitialState() {
        if (this.props.reduxGlobalState.currentLevel === constants.LEVEL_ENUM.ONE) {
            // Is first level, so is initial state
            return true;
        }
        else if (this.props.reduxGlobalState.currentLevel === constants.LEVEL_ENUM.TWO) {
            // Is second level, so is not initial state
            return false;
        }
    }

    handleNavItemClick(activeData) {
        // If user clicks on active subcategory item, close it:
        if (activeData === this.props.reduxGlobalState.activeSubcategoryData) {
            return this.deactivateSelectedSubcategory();
        }

        // Else, depending on state, activate selected item for category or subcategory:
        return this.isInitialState() ? this.activateSelectedCategory(activeData) : this.activateSelectedSubcategory(activeData);
    }

    handleImageMapClick(e) {
        // Image map was clicked and there is current active subcat data, so deselect it
        if (this.props.reduxGlobalState.activeSubcategoryData) {
            return this.deactivateSelectedSubcategory();
        }
    }

    shouldShowSideNav() {
        return this.props.reduxGlobalState.isMobile || !this.props.reduxGlobalState.hideSideNav;
    }

    scrollToTopOfContainer() {
        window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': constants.INFOGRAPHICS_REACTROOT.offsetTop - constants.UNIVERSAL_HEADER.clientHeight
        });
    }

    setParentClassName() {
        let parentClassName = `infographics`;

        // Add class based on application level (initial state or not)
        parentClassName += this.isInitialState() ? ' infographics-levelone' : ' infographics-leveltwo';

        // Add container class if no sidenav and is desktop
        parentClassName += this.props.reduxGlobalState.hideSideNav && !this.props.reduxGlobalState.isMobile ? ' container' : '';

        return parentClassName;
    }

    resetZoom() {
        let zoomChild = constants.INFOGRAPHICS_REACTROOT.querySelector('.zoomChild');
        zoomChild.style.transform = 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)';
        zoomChild.setAttribute('data-scale', 1);
        zoomChild.setAttribute('data-translate-x', 0);
        zoomChild.setAttribute('data-translate-y', 0);
    }

    slideMobileSidenav(e) {
        let actionIndicatorClicked = e.target.classList.contains('infographics-imagemap-actionindicator');

        if (actionIndicatorClicked &&
            !this.props.reduxGlobalState.isMobile ||
            this.props.reduxGlobalState.currentLevel === constants.LEVEL_ENUM.ONE) {
            return;
        }

        let sidenav = constants.INFOGRAPHICS_REACTROOT.querySelector('.infographics-sidenav');
        let imageMap = constants.INFOGRAPHICS_REACTROOT.querySelector('.infographics-imagemap-image');
        let imageMapHeight = imageMap.getBoundingClientRect().height;
        let slideAmount = imageMapHeight - 150;

        sidenav.style.marginTop === '' ?
            sidenav.style.marginTop = `-${slideAmount}px`
            :
            actionIndicatorClicked ?
                null
                :
                this.resetMobileSidenav();
    }

    resetMobileSidenav() {
        let sidenav = constants.INFOGRAPHICS_REACTROOT.querySelector('.infographics-sidenav');
        if (sidenav.style.marginTop !== '') {
            sidenav.style.marginTop = '';
        }
    }

    // RENDER
    render() {
        return (
            <div className={this.setParentClassName()}>

                {
                    this.shouldShowSideNav() &&
                    <SideNav
                        data={this.props.reduxGlobalState.contextData}
                        isMobile={this.props.reduxGlobalState.isMobile}
                        isInitialState={this.isInitialState()}
                        activeSubcategoryData={this.props.reduxGlobalState.activeSubcategoryData}
                        navItemClickHandler={this.handleNavItemClick}
                        backButtonClickHandler={this.reset}
                        backLinkText={this.props.reduxGlobalState.backLinkText}
                        sideNavSlideHandler={this.slideMobileSidenav}
                        levelClassName={this.setParentClassName()}
                        />
                }

                <ImageMap
                    data={this.props.reduxGlobalState.contextData}
                    isMobile={this.props.reduxGlobalState.isMobile}
                    isInitialState={this.isInitialState()}
                    hideSideNav={this.props.reduxGlobalState.hideSideNav}
                    activeSubcategoryData={this.props.reduxGlobalState.activeSubcategoryData}
                    actionIndicatorClickHandler={this.handleNavItemClick}
                    imageMapClickHandler={this.handleImageMapClick}
                    actionIndicatorCustomIconUrl={this.props.reduxGlobalState.actionIndicatorCustomIconUrl}
                    backButtonClickHandler={this.reset}
                    backLinkText={this.props.reduxGlobalState.backLinkText}
                    sideNavSlideHandler={this.slideMobileSidenav}
                />

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        reduxGlobalState: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfographicsContainer);