/*jshint esversion: 6 */

import React from 'react';

const ConvoUIAssistantPanelDefault = (props) => {
    return (
        <div className="universalconvoui-assistant universalconvoui-assistant-defaultview">
            {
                props.AssistantWatermark &&
                <p className="universalconvoui-assistant-watermark">
                    {props.AssistantWatermark}
                </p>
            }
            {
                props.data.Text &&
                <div className="universalconvoui-assistant-text-container">
                    {
                        props.greeting &&
                        <p className="universalconvoui-assistant-text-greeting">
                            {props.greeting}
                        </p>
                    }
                    <p className="universalconvoui-assistant-text">
                        {props.data.Text}
                    </p>
                </div>
            }
            <div className="universalconvoui-assistant-actions">
                {
                    props.data.AssistantButtonText &&
                    <button className='universalconvoui-assistant-actions-btn' onClick={props.engageAssistantWorkflowHandler}>
                        {props.data.AssistantButtonText}
                    </button>
                }
                {
                    props.data.ContactButtonText &&
                    <button className='universalconvoui-assistant-actions-btn' onClick={props.navigateToContactHandler}>
                        {props.data.ContactButtonText}
                    </button>
                }
            </div>
        </div>
    );
};

export default ConvoUIAssistantPanelDefault;