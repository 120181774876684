/*jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../appState/actions';

class ConvoUINav extends React.Component {
    constructor(props) {
        super(props);

        // Bind Statements
        this.navigateToAssistant = this.navigateToAssistant.bind(this);
        this.navigateToRecent = this.navigateToRecent.bind(this);
        this.navigateToContact = this.navigateToContact.bind(this);
    }

    navigateToAssistant() {
        if (this.props.globalState.shouldSearchForRecommendations()) {
            this.props.actions.getRecommendationsFromSearch();
        }
        this.props.actions.navigateToAssistantPanel();
        UniversalAnalytics.SendEvent('Convo UI', 'Assistant Button Click', 'Assistant Button Click', 10);
    }

    navigateToContact() {
        this.props.actions.navigateToContactPanel();
        UniversalAnalytics.SendEvent('Convo UI', 'Contact Us Button Click', 'Contact Us Button Click', 10);
    }

    navigateToRecent() {
        this.props.actions.navigateToRecentPanel();
        UniversalAnalytics.SendEvent('Convo UI', 'Recent Button Click', 'Recent Button Click', 10);
    }

    checkIfActive(button) {
        if (button) {
            return ' active';
        } else return '';
    }

    render() {
        return (
            <div className="universalconvoui-nav">
                {
                    this.props.globalState.dictionary.NavAssistantButtonText &&
                    <button
                        className={'universalconvoui-nav-btn universalconvoui-nav-assistant' + this.checkIfActive(this.props.globalState.showAssistant)}
                        onClick={this.navigateToAssistant}
                    >
                        {this.props.globalState.dictionary.NavAssistantButtonText}
                    </button>
                }
                {
                    this.props.globalState.dictionary.NavContactButtonText &&
                    <button
                        className={'universalconvoui-nav-btn universalconvoui-nav-contact' + this.checkIfActive(this.props.globalState.showContact)}
                        onClick={this.navigateToContact}
                    >
                        {this.props.globalState.dictionary.NavContactButtonText}
                    </button>
                }
                {
                    this.props.globalState.dictionary.NavRecentButtonText &&
                    <button
                        className={'universalconvoui-nav-btn universalconvoui-nav-recent' + this.checkIfActive(this.props.globalState.showRecent)}
                        onClick={this.navigateToRecent}
                    >
                        {this.props.globalState.dictionary.NavRecentButtonText}
                    </button>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        globalState: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvoUINav);