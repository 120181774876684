/*jshint esversion: 6 */

import React from 'react';

const ActionIndicator = (props) => {

    const buttonStyles = {
        left: props.navItem.Coordinates.X + '%',
        top: props.navItem.Coordinates.Y + '%',
        backgroundImage: props.actionIndicatorCustomIconUrl && `url(${props.actionIndicatorCustomIconUrl})`
    };

    const activeClass = props.isActive ? ' actionindicator-active' : '';

    function infographicsGAHotSpotClick(activeData) {
        UniversalAnalytics.SendEvent('Infographics', "Hot Spot Click", activeData.PopupData.Title.Text, 10);
    }

    return (
        <button
            className={'infographics-imagemap-actionindicator' + activeClass}
            style={buttonStyles}
            onClick={(e) => {
                props.actionIndicatorClickHandler(props.navItem); props.sideNavSlideHandler(e);
                infographicsGAHotSpotClick(props.navItem);
            }}
        />
    );
};

export default ActionIndicator;