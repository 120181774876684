/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let VideoModal = function (videoModal) {
    this.videoModal = videoModal;
    this.modalElement = this.videoModal.querySelector('.videomodal-modal');
    this.openModalButton = this.videoModal.querySelector('.videomodal-button, .videomodal-image-container-img');
    this.video = this.videoModal.querySelector('iframe, video');
    this.videoSrc = '';
    this.videoIsIframe = this.video.tagName === "IFRAME"; // else is VIDEO tag
};

VideoModal.prototype = {
    init: function () {
        if (this.videoModal === null
            || this.modalElement === null
            || this.openModalButton === null
            || this.video === null) {
            return;
        }

        let _this = this;

        if (_this.videoIsIframe) {
            _this.setDefaultIframeVideoSrc(_this);
        }

        _this.openModalButton.addEventListener('click', function () {
            _this.openModal(_this);
            _this.playVideo(_this);
        });

        _this.modalElement.addEventListener('click', function (e) {
            if (e.target === _this.modalElement) {
                _this.stopVideo(_this);
                _this.closeModal(_this);
            }
        });
    },

    setDefaultIframeVideoSrc(_this) {
        _this.videoSrc = _this.video.getAttribute('src');
    },

    openModal: function (_this) {
        _this.modalElement.classList.add('showModal');
    },

    closeModal: function (_this) {
        _this.modalElement.classList.remove('showModal');
    },

    playVideo: function (_this) {
        if (_this.videoIsIframe) {
            _this.video.setAttribute('allow', 'autoplay');
            _this.video.setAttribute('src', _this.videoSrc + '?autoplay=1');
        }
        else {
            _this.video.play();
        }
    },

    stopVideo: function (_this) {
        if (_this.videoIsIframe) {
            _this.video.setAttribute('allow', '');
            _this.video.setAttribute('src', '');
        }
        else {
            _this.video.pause();
        }
    }
};

window.addEventListener('load', function () {
    let videoModals = document.querySelectorAll('.videomodal');
    if (videoModals) {
        videoModals.forEach(function (videoModal) {
            let vm = new VideoModal(videoModal);
            vm.init();
        });
    }
});