/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let LocationFinderResults = function (resultsComponent) {
    this.resultsComponent = resultsComponent;
    this.invalidAddressText = $(resultsComponent).data('invalidaddresstext');
    this.autocompleteId = $(resultsComponent).data('autocompleteid');
    this.useGoogle = $(resultsComponent).data('usegoogle');
    this.bingApiKey = $(resultsComponent).data('bingapikey');
    this.units = $(resultsComponent).data('units');
    this.bgImage = $(resultsComponent).data('bgimage');
    this.NearbyLocationsUrl = $(resultsComponent).data('nearbylocationsurl');
    this.NearbyLocationsWithSearchTermsUrl = $(resultsComponent).data('nearbylocationswithsearchtermsurl');
    this.NearbyLocationsOfTypeUrl = $(resultsComponent).data('nearbylocationsoftypeurl');
    this.NearbyLocationsOfTypeWithSearchTermsUrl = $(resultsComponent).data('nearbylocationsoftypewithsearchtermsurl');
    this.ReportLocationsUrl = $(resultsComponent).data('reportlocationsurl');
    this.ClosestPreferredVendorUrl = $(resultsComponent).data('closestpreferredvendorurl');
    this.ClosestPreferredVendorWithSearchTermsUrl = $(resultsComponent).data('closestpreferredvendorwithsearchtermsurl');
    this.ClosestPreferredVendorOfTypeUrl = $(resultsComponent).data('closestpreferredvendoroftypeurl');
    this.ClosestPreferredVendorOfTypeWithSearchTermsUrl
        = $(resultsComponent).data('closestpreferredvendoroftypewithsearchtermsurl');
    this.ResultsLanguage = $(resultsComponent).data('resultslanguage');
};

LocationFinderResults.prototype = {
    initialize: function () {
        if (jQuery('.breadcrumb-control').length) {
            jQuery('.main-content').removeClass('location-finder-header-offset');
        }

        jci.locationFinderConstants.init(this.invalidAddressText);
        jci.locationFinder.init(this.autocompleteId,
            this.useGoogle,
            this.bingApiKey,
            this.NearbyLocationsUrl,
            this.NearbyLocationsWithSearchTermsUrl,
            this.NearbyLocationsOfTypeUrl,
            this.NearbyLocationsOfTypeWithSearchTermsUrl,
            this.ReportLocationsUrl,
            this.ClosestPreferredVendorUrl,
            this.ClosestPreferredVendorWithSearchTermsUrl,
            this.ClosestPreferredVendorOfTypeUrl,
            this.ClosestPreferredVendorOfTypeWithSearchTermsUrl,
            this.ResultsLanguage,
            this.units,
            this.bgImage);
    }
};

window.addEventListener('load', function () {
    let allResults = document.querySelectorAll('.universallocationfinderresults');
    if (allResults) {
        allResults.forEach(function (results) {
            // Construct new LocationFinderResults
            var locationFinderResults = new LocationFinderResults(results);
            locationFinderResults.initialize();
        });
    }
});