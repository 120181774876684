/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';

var CookieDisclaimer = function (cookieDisclaimerComponent) {
    this.cookieDisclaimerComponent = cookieDisclaimerComponent;
    this.acceptButton = this.cookieDisclaimerComponent.querySelector('.universalcookiedisclaimer-btn');
};

CookieDisclaimer.prototype.Init = function () {
    var _this = this;

    this.acceptButton.addEventListener('click', function () {
        _this.Close();
    });
};

CookieDisclaimer.prototype.Close = function () {
    jQuery.post("/universalapi/Feature/Navigation/UniversalCookieDisclaimer/AcceptCookies");
    this.cookieDisclaimerComponent.style.display = "none";
};

CookieDisclaimer.Activate = function () {
    let allcookieDisclaimers = document.querySelectorAll('.universalcookiedisclaimer');
    if (allcookieDisclaimers) {
        allcookieDisclaimers.forEach(function (cookieDisclaimerComponent) {
            // Construct new FeaturedData
            var cookieDisclaimer = new CookieDisclaimer(cookieDisclaimerComponent);

            // Init functionality on load
            cookieDisclaimer.Init();
        });
    }
}

CookieDisclaimer.GetApiUrl = function (languageCode) {
    var url = "/universalapi/Feature/Navigation/UniversalCookieDisclaimer/GetCookieDisclaimerMessage";
    if (typeof(languageCode) !== "undefined") {
        url = "/" + languageCode + url;
    }
    return url;
};

CookieDisclaimer.TryLoad = function (languageCode) {

    var disclaimerUrl = this.GetApiUrl(languageCode);
    jQuery.get(disclaimerUrl, function (data) {
        jQuery(".universalheader").after(data);
        CookieDisclaimer.Activate();
    });
};

window.CookieDisclaimer = CookieDisclaimer;
    