/*jshint esversion: 6 */
// Mobile Navigation ------------------------------------
function openNav() {
    document.getElementById("fsNav").style.left = "0px";
    document.body.style.overflow = "hidden";
}

// Search Overlay ------------------------------------
function showSearchOverlay() {
    $('.universalheadersearchoverlay:not(.showSearchOverlay)').addClass('showSearchOverlay');
    $('.universalheadersearchoverlay-searchbar .CoveoSearchbox .magic-box .magic-box-input > input, .universalheadersearchoverlay-searchbar-input').focus();
}
function hideSearchOverlay() {
    $('.universalheadersearchoverlay.showSearchOverlay').removeClass('showSearchOverlay');
}
$('.universalheader .search-button-desktop').on('click', showSearchOverlay);
$('.universalheadersearchoverlay-close').on('click', hideSearchOverlay);
$(window).on('resize scroll', hideSearchOverlay);

// Reveal Dropdown
$(".has-dropdown").click(function () {
    $(this).find(".dropdown").css("left", "0px");
});
$(".closeSubNav").click(function () {
    $(".dropdown").css("left", "1000px");
    return false;
});

$("#search-mobile").keypress(function (e) {
    if (13 === e.which) {
        var t = this.getAttribute("data-url"),
            a = $("#search-mobile").val();
        window.location.href = t + "#q=" + a;
    }
});

function closeNav() {
    // slide left
    document.getElementById("fsNav").style.left = "1000px";
    document.body.style.overflow = "scroll";
    $(".dropdown").css("left", "1000px");
    return false;
}

// Open/Close
$('.openNav').click(openNav);
$('.closeNav').click(closeNav);


// Close Nav (Click outside of Nav Overlay)
$(document).click(function (event) {
    if (!$(event.target).closest('#fsNav').length) {
        if ($("#fsNav").css("left") === "0px") {
            document.getElementById("fsNav").style.left = "1000px";
            $(".dropdown").css("left", "1000px");
            return false;
        }
    }
});


// Main Nav -------------------------------------
let opened = null,
    trigger = document.getElementsByClassName('trigger');

for (let i = 0; i < trigger.length; i++) {
    trigger[i].addEventListener('click', function (e) {

        // gets the dropdown of the clicked menu item
        let menu = this.querySelector('.dropdown');

        if (!menu.contains(e.target)) {
            menus(menu);
        }
    });
}

function menus(menu) {
    if (!opened) {
        // if no menu item is shown
        opened = menu;
        opened.classList.toggle('show');

    } else if (menu === opened) {
        // the clicked item is already showing
        menu.classList.toggle('show');
        opened = null;
    } else {
        // the clicked item is hidden but another one is showing
        opened.classList.toggle('show');
        opened = menu;
        opened.classList.toggle('show');
    }
}

// Hide Dropdown on scroll
window.onscroll = function () { hideMenu() };
function hideMenu() {
    let dropdown = document.querySelector('.dropdown.show');
    let body = document.querySelector('body');
    if (dropdown === null) {
        return false;
    } else if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
        dropdown.classList.remove('show');
        body.classList.remove('no-scroll');
    }
}