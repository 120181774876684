/*jshint esversion: 6 */

import React from 'react';
import ConvoUIReset from '../shared/reset';
import ConvoUIContentItem from '../shared/contentitem';

const ConvoUIAssistantPanelReturn = (props) => {
    return (
        <div className="universalconvoui-assistant universalconvoui-assistant-returnview">
            {
                props.AssistantWatermark &&
                <p className="universalconvoui-assistant-watermark">
                    {props.AssistantWatermark}
                </p>
            }
            {
                props.Text &&
                <div className="universalconvoui-assistant-text-container">
                    {
                        props.greeting &&
                        <p className="universalconvoui-assistant-text-greeting">
                            {props.greeting}
                        </p>
                    }
                    <p className="universalconvoui-assistant-text">
                        {props.Text}
                    </p>
                </div>
            }
            {
                props.recommendations.length > 0 &&
                <ul className="universalconvoui-contentitem-list">
                    {
                        props.recommendations.map((recommendation, index) => {
                            return <ConvoUIContentItem key={index} data={recommendation} closeSidebarHandler={props.closeSidebarHandler} />;
                        })
                    }
                </ul>
            }   

            <ConvoUIReset
                resetRecommendationsHandler={props.resetRecommendationsHandler}
                resetButtonText={props.resetButtonText}
            />
        </div>
    );
};

export default ConvoUIAssistantPanelReturn;