/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let SiteSearch = function (search) {
    this.siteSearch = search;
    this.facetsAreVisible = null;
};

SiteSearch.prototype = {
    init: function () {
        if (this.siteSearch) {
            let _this = this;

            // Coveo event that runs at the end of a search interface update
            _this.siteSearch.addEventListener('deferredQuerySuccess', function () {
                setTimeout(function () { // Need setTimeout b/c Coveo markup is not fully updated even before last event
                    _this.checkIfFacetsAllHidden(_this);
                    _this.setFacetColumnHeaderVisibility(_this);
                }, 50);
            });
        }
    },

    checkIfFacetsAllHidden: function (_this) {
        let visibleFacets = _this.siteSearch.querySelectorAll('.CoveoFacet:not(.coveo-facet-empty');
        _this.facetsAreVisible = visibleFacets.length !== 0;
    },

    setFacetColumnHeaderVisibility: function (_this) {
        let facetColumnHeader = _this.siteSearch.querySelector('.coveo-facet-header-filter-by');
        if (_this.facetsAreVisible) {
            facetColumnHeader.style.display = 'block';
        } else {
            facetColumnHeader.style.display = 'none';
        }
    }
};

// Init
window.addEventListener('load', function () {
    let siteSearch = document.querySelectorAll('.site-search');
    if (siteSearch) {
        siteSearch.forEach(function (search) {
            let siteSearch = new SiteSearch(search);
            siteSearch.init();
        });
    }
});