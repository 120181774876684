import * as constants from './constants';

export function navigateToContactPanel() {
    return {
        type: constants.NAV_TO_CONTACT
    };
}

export function navigateToRecentPanel() {
    return {
        type: constants.NAV_TO_RECENT
    };
}

export function navigateToAssistantPanel() {
    return {
        type: constants.NAV_TO_ASSISTANT
    };
}

export function declineAssistance() {
    return {
        type: constants.DECLINE_ASSISTANCE
    };
}

export function declineCheckIn() {
    return {
        type: constants.DECLINE_CHECK_IN
    };
}

export function checkIn() {
    return {
        type: constants.CHECK_IN
    };
}

export function engageAssistantWorkflow() {
    return {
        type: constants.ENGAGE_ASSISTANT_WORKFLOW
    };
}

export function getRecommendationsFromMappings() {
    return {
        type: constants.PROVIDE_MAPPED_RECOMMENDATIONS
    };
}

export function resetRecommendations() {
    return {
        type: constants.RESET_RECOMMENDATIONS
    };
}

export function saveAnswer(questionName, answerObject) {
    return {
        type: constants.SAVE_ANSWER,
        questionName: questionName,
        answerObject: answerObject
    };
}

export function getRecommendationsFromSearch() {
    return {
        type: constants.PROVIDE_SEARCH_RECOMMENDATIONS
    };
}