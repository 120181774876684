/*jshint esversion: 6 */
// Accordions ========================================================= *
// Accordion Item
var accItems = document.querySelectorAll('.accordionItem');
// Accordion Heading
var accHD = document.getElementsByClassName('accordionItemHeading');
// Brand Bar 
let brandBar = document.querySelector('.utilitybrandbar');
let body = document.querySelector('body');

// Heading
for (var i = 0; i < accHD.length; i++) {
    accHD[i].addEventListener('click', toggleItem, false);
}

function toggleItem(e) {
    accItems.forEach(function (accItem) {
        if (accItem === e.target.parentNode) {
            $(e.target.parentNode).toggleClass('close');
            setBodyScroll(e.target.parentNode.classList.contains('close'));
        }
        else if (!accItem.classList.contains('close')) {
            accItem.classList.add('close');
        }
    });
}

function setBodyScroll(enableBodyScroll) {
    if (enableBodyScroll && body.classList.contains('no-scroll')) {
        body.classList.remove('no-scroll');
        brandBar.style.marginLeft = "0px";
    }
    else if (!body.classList.contains('no-scroll')) {
        body.classList.add('no-scroll');
        brandBar.style.marginLeft = "-10px";
    }
}

// Sub-Accordion Item (Child)
var subAccItem = document.getElementsByClassName('subAccordionItem');
// Accordion Heading
var subAccHD = document.getElementsByClassName('subAccordionItemHeading');
// Heading
for (i = 0; i < subAccHD.length; i++) {
    subAccHD[i].addEventListener('click', toggleSubItem, false);
}
function toggleSubItem() {
    var itemSubClass = this.parentNode.className;
    for (i = 0; i < subAccItem.length; i++) {
        subAccItem[i].className = 'subAccordionItem close';
    }
    if (itemSubClass === 'subAccordionItem close') {
        this.parentNode.className = 'subAccordionItem open';
    }
}