/*jshint esversion: 6 */

import React from 'react';

const ConvoUIReset = (props) => {
    return (
        <div className='universalconvoui-assistant-reset'>
            <button className='universalconvoui-assistant-reset-btn' onClick={props.resetRecommendationsHandler}>
                {props.resetButtonText}
            </button>
        </div>
    );
};

export default ConvoUIReset;