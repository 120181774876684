import React from 'react';

const ConvoUIPromptDefault = (props) => {
    return (
        <div className="universalconvoui-prompt">
            {
                props.data.Eyebrow &&
                <small className='universalconvoui-prompt-eyebrow'>
                    {props.data.Eyebrow}
                </small>
            }
            {
                props.data.Text &&
                <p className='universalconvoui-prompt-dialog'>
                    {props.data.Text}
                </p>
            }
            <div className='universalconvoui-prompt-actions'>
                {
                    props.data.AcceptButtonText &&
                    <button className='universalconvoui-prompt-actions-btn' onClick={props.acceptAssistanceHandler}>
                        {props.data.AcceptButtonText}
                    </button>
                }
                {
                    props.data.DeclineButtonText &&
                    <button className='universalconvoui-prompt-actions-btn' onClick={props.declineAssistanceHandler}>
                        {props.data.DeclineButtonText}
                    </button>
                }
            </div>
        </div>
    );
};

export default ConvoUIPromptDefault;