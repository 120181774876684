/*jshint esversion: 6 */

import React from 'react';

const BackButton = (props) => {

    function infographicsGABackButtonClick() {
        UniversalAnalytics.SendEvent('Infographics', 'Level 2 Back Button Click', "Level 2 Back Button Click", 10);
    }

    return (
        <button className='infographics-backbutton' onClick={() => { props.backButtonClickHandler(), infographicsGABackButtonClick()}}>
            {props.backLinkText}
        </button>
    );
};

export default BackButton;