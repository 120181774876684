
let UniversalOtherOptionSelectList = function (selectListComponent) {
    this.selectListComponent = selectListComponent;
    this.dropdown = this.selectListComponent.querySelector('.universalotheroptionselectlist-ddn');
    this.textBox = this.selectListComponent.querySelector('.universalotheroptionselectlist-input');
    this.otherOptionDiv = this.selectListComponent.querySelector('.universalotheroptioninputfield');
    this.otherOptionValue = this.dropdown.getAttribute("data-sc-otheroption");
};
UniversalOtherOptionSelectList.prototype.Init = function () {
    var _this = this;
  
    _this.RenderOtherOptionControl();

    this.dropdown.addEventListener('change', function () {
        _this.RenderOtherOptionControl();
    });
};

UniversalOtherOptionSelectList.prototype.RenderOtherOptionControl = function () {

    var _this = this;
    var selectedValue = this.dropdown.value;

    if (_this.otherOptionValue === selectedValue) {
        _this.otherOptionDiv.style.display = "inline-block";
        _this.textBox.value = "";
    } else {
        _this.otherOptionDiv.style.display = "none";
    }
};


window.addEventListener('load', function () {
    let selectLists = document.querySelectorAll('.universalotheroptionselectlist');

    if (selectLists) {
        selectLists.forEach(function (selectListComponent) {
            // Construct new OtherOptionSelectList
            var selectList = new UniversalOtherOptionSelectList(selectListComponent);

            // Init functionality on load
            selectList.Init();
        });
    }
});
