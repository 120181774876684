var searchInterface = $('.CoveoSearchInterface');
var insightGrid = $('.insight-grid');

if (searchInterface && searchInterface.length && insightGrid && insightGrid.length) {
    $("#" + searchInterface[0].id).on('newResultsDisplayed', function () {
        $('.coveo-result-list-container').each(function (index, container) {
            $(container).addClass('grid');
        });

        $('.CoveoResult').each(function (index, item) {
            $(item).addClass('grid-item');
        });

       $("<div class='grid-sizer'></div>").prependTo(".coveo-result-list-container.grid");

    });

    //$(".show-more-results-button").on('click', function (e, args) {
    //    e.preventDefault();
    //    $("#" + searchInterface[0].id + " .CoveoResultList").coveo('displayMoreResults', 9);
    //});
}

// Toggle Filter Facets
$(".insight-grid .filters").click(function (e) {
    e.preventDefault();
    $(".coveo-facet-column").show();
});

$(".insight-grid .close").click(function (e) {
    e.preventDefault();
    $(".coveo-facet-column").hide();
});

