import React from 'react';

const ConvoUIPromptResting = (props) => {
    if (props.data.AcceptButtonText) {
        return (
            <div className="universalconvoui-prompt-resting">
                <button className="universalconvoui-prompt-resting-btn" onClick={props.navigateToAssistantHandler}>
                    {props.data.AcceptButtonText}
                </button>
            </div>
        );
    }
    else {
        return null;
    }
};

export default ConvoUIPromptResting;