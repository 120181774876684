/*jshint esversion: 6 */
import '../Views/Feature/Components/UniversalAnchorBar/universalAnchorBar';
import '../Views/Feature/Components/FeaturedData/featuredData';
import '../Views/Feature/Components/FeaturedDataVariant2/featuredDataVariant2';
import '../Views/Feature/Components/VideoAnchorButton/videoAnchorButton';
import '../Views/Feature/Components/ExpandableCallout/expandableCallout';
import '../Views/Feature/Components/TabControl/tabControl';
import '../Views/Feature/Components/ImageGallery/imageGallery';
import '../Views/Feature/Components/TechnicalSupportPanel/technicalSupportPanel';
import '../Views/Feature/Components/ProductBar/productBar';
import '../Views/Feature/Components/LoadMoreResults/LoadMoreResults';
import '../Views/Feature/Components/CampaignHeader/campaignHeader';
import '../Views/Feature/Components/VideoModal/videoModal';
import '../Views/Feature/Components/ScrollDownIndicator/scrolldownindicator';
import '../Views/Feature/Components/ProductCardList/productCardList';
import '../Views/Feature/Components/Infographics/infographics';
