/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let ImageGallery = function (imageGalleryElement) {
    this.imageGalleryElement = imageGalleryElement;
    this.imageGalleryItems = this.imageGalleryElement.querySelectorAll('.universalimagegallery-item');
    this.captionContainer = this.imageGalleryElement.querySelector('.universalimagegallery-carousel-active-caption');
};

ImageGallery.prototype = {
    init: function () {
        let _this = this;

        // If more than one image, need to deal with caption positioning b/c nav will be visible & in the way
        if (_this.imageGalleryItems.length > 1) {
            _this.updateCaption(_this);

            // 'Slid' Event (Bootstrap event that fires after carousel has updated)
            $(_this.imageGalleryElement).on('slid.bs.carousel', function () {
                _this.updateCaption(_this);
            });
        }
    },

    updateCaption: function (_this) {
        let activeItemCaption = _this.imageGalleryElement.querySelector('.carousel-item.active .universalmedia-caption');

        if (activeItemCaption) {
            $(activeItemCaption).hide();
            _this.captionContainer.innerHTML = activeItemCaption.innerHTML;
        } else {
            _this.captionContainer.innerHTML = "";

        }
    }
};

// Init
let imageGalleries = document.querySelectorAll('.universalimagegallery');
if (imageGalleries) {
    // Construct new Expandable Callouts
    imageGalleries.forEach(function (imageGalleryElement) {
        let imageGallery = new ImageGallery(imageGalleryElement);
        imageGallery.init();
    });
}