import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

export default function configureStore(initialState) {
    return createStore(
        reducer,
        initialState,
        applyMiddleware(reduxImmutableStateInvariant())
    );
}