/*jshint esversion: 6 */

import React from 'react';
import NavItem from './child-components/navitem';
import BackButton from '../shared/backbutton';

const SideNav = (props) => {
    return (
        <div className='infographics-sidenav'  >
            {
                props.isMobile &&
                <button
                    className='infographics-sidenav-slider'
                    onClick={props.sideNavSlideHandler}
                />
            }

            {
                !props.isInitialState && !props.isMobile &&
                <BackButton
                    backButtonClickHandler={props.backButtonClickHandler}
                    backLinkText={props.backLinkText}
                />
            }

            {
                props.data.Title &&
                <h1 className='infographics-sidenav-title'>
                    {props.data.Title}
                </h1>
            }

            {
                // If desc exists
                // AND if there is no active subCat on mobile OR is desktop
                props.data.Description &&
                (props.isMobile && !props.activeSubcategoryData || !props.isMobile) &&
                <p className='infographics-sidenav-description'>
                    {props.data.Description}
                </p>
            }

            {
                props.data.NavItems.map((navItem, index) => {
                    // If is desktop, show all nav items
                    // Or, if is mobile and L1 state, show all nav items
                    // Or, if is mobile and L2 state, show ONLY active nav item
                    if (!props.isMobile
                        || props.isMobile && props.isInitialState
                        || props.isMobile && !props.isInitialState && navItem === props.activeSubcategoryData) {
                        return (
                            <NavItem
                                key={index}
                                navItem={navItem}
                                levelClassName={props.levelClassName}
                                navItemClickHandler={props.navItemClickHandler}
                                isActiveNavItem={navItem === props.activeSubcategoryData}
                            />
                        );
                    }
                })
            }
        </div>
    );
};

export default SideNav;