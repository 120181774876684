var FormRoutingControl = function (formConfigurationId, formId, language, database) {
    this.FormConfigurationId = formConfigurationId;
    this.FormId = formId;
    this.Language = language;
    this.Database = database;

    this.CountryDropdown = document.querySelector('.country-select');
    this.CategoryDropdown = document.querySelector('.category-select');
    this.SubcategoryDropdown = document.querySelector('.subcategory-select');
    this.BrandDropdown = document.querySelector('.brand-select');
    
    this.CountrySectionSelector = ".country-section";
    this.CategorySectionSelector = ".category-section";
    this.SubcategorySectionSelector = ".subcategory-section";
    this.BrandSectionSelector = ".brand-section";

    this.CountrySection = document.querySelector('.country-section');
    this.CategorySection = document.querySelector('.category-section');
    this.SubcategorySection = document.querySelector('.subcategory-section');
    this.BrandSection = document.querySelector('.brand-section');

    this.SelectedCountry = "";
    this.SelectedCategory = "";
    this.SelectedSubcategory = "";
    this.SelectedBrand = "";

    this.ValidateFormConfigurationId();
    this.hostname = document.domain.toLowerCase();
}

FormRoutingControl.prototype.Init = function () {
    var _this = this;

    if (this.FormConfigurationId == null) {
        this.GetFormConfigurationId();
    }

    this.GetCountries();

    this.CountryDropdown.addEventListener('change', function (eventArgs) {
        _this.CountryDropdownOnChange(eventArgs);
    });

    this.CategoryDropdown.addEventListener('change', function (eventArgs) {
        _this.CategoryDropdownOnChange(eventArgs);
    });

    this.SubcategoryDropdown.addEventListener('change', function (eventArgs) {
        _this.SubcategoryDropdownOnChange(eventArgs);
    });
}

FormRoutingControl.prototype.CountryDropdownOnChange = function (eventArgs) {
    this.SelectedCountry = this.CountryDropdown.options[this.CountryDropdown.selectedIndex].value;
    this.ResetControl(this.CategoryDropdown);
    this.ResetControl(this.SubcategoryDropdown);
    this.ResetControl(this.BrandDropdown);
    if (this.hostname.includes('sensormatic.')) {
        this.HideControl(this.SubcategorySection);
    }
    else {
        this.ShowControl(this.SubcategorySection);
    }
    this.GetCategories(this.SelectedCountry);
}

FormRoutingControl.prototype.CategoryDropdownOnChange = function (eventArgs) {
    this.SelectedCategory = this.CategoryDropdown.options[this.CategoryDropdown.selectedIndex].value;
    this.ResetControl(this.SubcategoryDropdown);
    this.ResetControl(this.BrandDropdown);
    if (this.hostname.includes('sensormatic.')) {
        this.HideControl(this.SubcategorySection);
    }
    else {
        this.ShowControl(this.SubcategorySection); 
    }
    this.GetSubcategories(this.SelectedCountry, this.SelectedCategory);
}

FormRoutingControl.prototype.SubcategoryDropdownOnChange = function (eventArgs) {
    this.SelectedSubcategory = this.SubcategoryDropdown.options[this.SubcategoryDropdown.selectedIndex].value;
    this.ResetControl(this.BrandDropdown);
    this.GetBrandsBySubcategory(this.SelectedCountry, this.SelectedCategory, this.SelectedSubcategory);
}

FormRoutingControl.prototype.ResetControl = function (control) {
    control.options.length = 0;
    this.DisableControl(control);
}

FormRoutingControl.prototype.ValidateFormConfigurationId = function () {
    var _this = this;

    if (this.FormConfigurationId != null) {
        this.Init();
    }
    else {
        $.ajax({
            type: "GET",
            url: '/' + this.Language + '/universalapi/Feature/Forms/FormConfiguration/GetFormConfigurationId',
            data: { formId: _this.FormId, database: _this.Database },
            success: function success(data) {
                _this.FormConfigurationId = data;
                _this.Init();
            },
            fail: function fail() {
                console.log("Fail: Failed to load Form Configuration");
            },
            error: function error() {
                console.log("Error: Failed to load Form Configuration");
                _this.HideAllSections();
            },
            dataType: "json"
        });
    }
};

FormRoutingControl.prototype.GetCountries = function () {
    var _this = this;

    $.ajax({
        type: "GET",
        url: '/' + this.Language + '/universalapi/Feature/Forms/FormConfiguration/GetCountries',
        data: { formConfigurationId: _this.FormConfigurationId, database: _this.Database },
        success: function (data) {
            _this.LoadSelect(".country-select", data);
        },
        fail: function () {
            console.log("Fail: Failed to load countries");
            console.log("form configuration id = " + _this.FormConfigurationId);
        },
        error: function error() {
            console.log("Error: Failed to load countries");
            console.log("form configuration id = " + _this.FormConfigurationId);
            _this.HideAllSections();
        },
        dataType: "json"
    });
}

FormRoutingControl.prototype.GetCategories = function (countryId) {
    var _this = this;

    $.ajax({
        type: "GET",
        url: '/' + this.Language + '/universalapi/Feature/Forms/FormConfiguration/GetCategories',
        data: { formConfigurationId: _this.FormConfigurationId, countryId: countryId, database: _this.Database },
        success: function (data) {
            _this.LoadSelect(".category-select", data);
        },
        fail: function () {
            console.log("Fail: Failed to load categories");
            console.log("form configuration id = " + _this.FormConfigurationId);
            console.log("country id = " + countryId);
        },
        error: function error() {
            console.log("Error: Failed to load categories");
            console.log("form configuration id = " + _this.FormConfigurationId);
            console.log("country id = " + countryId);
            _this.HideAllSections();
        },
        dataType: "json"
    });
}

FormRoutingControl.prototype.GetSubcategories = function (countryId, categoryId) {
    var _this = this;
    var host = document.domain.toLowerCase();
    $.ajax({
        type: "GET",
        url: '/' + this.Language + '/universalapi/Feature/Forms/FormConfiguration/GetSubcategories',
        data: { formConfigurationId: _this.FormConfigurationId, countryId: countryId, categoryid: categoryId, database: _this.Database },
        success: function (data) {
            if ($.isEmptyObject(data)) {
                _this.SuppressSubcategories(countryId, categoryId);
            } else {
                if (host.includes('sensormatic.')) {
                    $('.subcategory-section').show();
                }
                _this.LoadSelect(".subcategory-select", data);
            }
        },
        fail: function () {
            console.log("Fail: Failed to load sub categories");
            console.log("form configuration id = " + _this.FormConfigurationId);
            console.log("country id = " + countryId);
            console.log("category id = " + categoryId);
        },
        error: function error() {
            console.log("Error: Failed to load sub categories");
            console.log("form configuration id = " + _this.FormConfigurationId);
            console.log("country id = " + countryId);
            console.log("category id = " + categoryId);
            _this.HideAllSections();
        },
        dataType: "json"
    });
}

FormRoutingControl.prototype.SuppressSubcategories = function(countryId, categoryId) {
    this.HideControl(this.SubcategorySection);
    this.GetBrandsByCategory(countryId, categoryId);
}

FormRoutingControl.prototype.GetBrandsByCategory = function (countryId, categoryId) {
    var _this = this;

    $.ajax({
        type: "GET",
        url: '/' + this.Language + '/universalapi/Feature/Forms/FormConfiguration/GetBrandsByCategory',
        data: { formConfigurationId: _this.FormConfigurationId, countryId: countryId, categoryId: categoryId, database: _this.Database },
        success: function (data) {
            _this.LoadSelect(".brand-select", data);
        },
        fail: function () {
            console.log("Fail: Failed to load brands");
            console.log("form configuration id = " + _this.FormConfigurationId);
            console.log("country id = " + countryId);
            console.log("category id = " + categoryId);
        },
        error: function error() {
            console.log("Error: Failed to load brands");
            console.log("form configuration id = " + _this.FormConfigurationId);
            console.log("country id = " + countryId);
            console.log("category id = " + categoryId);
            _this.HideAllSections();
        },
        dataType: "json"
    });
}


FormRoutingControl.prototype.GetBrandsBySubcategory = function (countryId, categoryId, subcategoryId) {
    var _this = this;

    $.ajax({
        type: "GET",
        url: '/' + this.Language + '/universalapi/Feature/Forms/FormConfiguration/GetBrandsBySubcategory',
        data: { formConfigurationId: _this.FormConfigurationId, countryId: countryId, categoryId: categoryId, subcategoryId: subcategoryId, database: _this.Database },
        success: function (data) {
            _this.LoadSelect(".brand-select", data);
        },
        fail: function () {
            alert("failed to load brands");
        },
        dataType: "json"
    });
}

FormRoutingControl.prototype.LoadSelect = function (selector, options) {
    this.EnableControl(selector);

    $(selector).empty();

    $(selector)
        .append($("<option></option>")
            .attr("value", "default")
            .attr("selected", "selected")
            .attr("disabled", "disabled")
            .text("Choose Option"));

    $.each(options, function (key, value) {
        $(selector)
            .append($("<option></option>")
                .attr("value", key)
                .text(value));
    });
}

FormRoutingControl.prototype.DisableControl = function (control) {
    $(control).prop("disabled", true);
}

FormRoutingControl.prototype.EnableControl = function (control) {
    $(control).prop("disabled", false);
}

FormRoutingControl.prototype.HideControl = function (control) {
    $(control).hide();
}

FormRoutingControl.prototype.ShowControl = function (control) {
    $(control).show();
}

FormRoutingControl.prototype.HideAllSections = function (control) {
    this.HideControl(this.CountrySection);
    this.HideControl(this.CategorySection);
    this.HideControl(this.SubcategorySection);
    this.HideControl(this.BrandSection);
}

window.FormRoutingControl = FormRoutingControl;


