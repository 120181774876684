/*jshint esversion: 6 */

import React from 'react';

const DocumentLink = (props) => {
    return (
        <a
            href={props.document.Url}
            className='infographics-documentlink'
            target='_blank'
        >
            {
                props.document.IconUrl &&
                <img className='infographics-documentlink-icon' src={props.document.IconUrl}/>
            }

            {props.document.Text}
        </a>
    );
};

export default DocumentLink;