/*jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as constants from '../appState/constants';
import * as appActions from '../appState/actions';
import ConvoUIAssistantPanelDefault from './panel-states/assistant-default';
import ConvoUIAssistantPanelReturn from './panel-states/assistant-return';
import ConvoUIAssistantPanelWorkflow from './panel-states/assistant-workflow';
import ConvoUIAssistantPanelRecommendations from './panel-states/assistant-recommendations';
import ConvoUIRecentPanelPopulated from './panel-states/recent-populated';
import ConvoUIRecentPanelInitial from './panel-states/recent-initial';

class ConvoUIPanelContainer extends React.Component {
    constructor(props) {
        super(props);

        // Add to window b/c we need to use this as well as remove the listener when appropriate
        // Inside context of this, doing both of the above can't work
        // Need to use window context for more open access
        let _this = this;
        window.convoUIrunLogicForDocumentClicks = function (event) {
            if (!$(event.target).closest('.showSidebar').length > 0) {
                _this.props.actions.declineAssistance();
            }
        };

        // Bind Statements
        this.navigateToContact = this.navigateToContact.bind(this);
        this.engageAssistantWorkflow = this.engageAssistantWorkflow.bind(this);
        this.resetRecommendations = this.resetRecommendations.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
        this.inputHasChanged = this.inputHasChanged.bind(this);
    }

    // DOM UPDATES 
    componentDidUpdate() {
        let _this = this;
        let questions = document.querySelectorAll('.universalconvoui-question');
        let lastQuestion = questions[questions.length - 1];

        if (this.props.globalState.showSidebar) {
            if (this.props.globalState.showAssistant &&
                this.props.globalState.showAssistantWorkflow &&
                questions.length > 1) {
                // If more than one question, scroll to last question
                _this.scrollToTarget(lastQuestion);
            }
            else {
                // Scroll to top
                _this.scrollToTarget(constants.SIDEBAR_ELEMENT);
            }
        }
    }
    showSidebar() {
        constants.SIDEBAR_ELEMENT.classList.add('showSidebar');
        document.body.addEventListener('click', window.convoUIrunLogicForDocumentClicks);
    }
    hideSidebar() {
        constants.SIDEBAR_ELEMENT.classList.remove('showSidebar');
        document.body.removeEventListener('click', window.convoUIrunLogicForDocumentClicks);
    }
    showContact() {
        constants.CONTACT_ELEMENT.classList.add('showContactPanel');
    }
    hideContact() {
        constants.CONTACT_ELEMENT.classList.remove('showContactPanel');
    }

    // HELPERS
    replaceTokens(object, token, replacement) {
        let objectStringified = JSON.stringify(object);
        let tokenRegex = new RegExp(token, "g");
        return JSON.parse(objectStringified.replace(tokenRegex, replacement));
    }
    determineGreeting() {
        if (this.props.globalState.isReturnVisit && this.props.globalState.hasAnsweredQuestionsBefore) {
            return this.props.globalState.dictionary.WelcomeBack;
        } else {
            let now = new Date();
            let nowTime = '' + now.getHours() + (now.getMinutes() < 10 ? '0' : '') + now.getMinutes();

            switch (true) {
                case nowTime >= 0 && nowTime <= 359:
                    return this.props.globalState.dictionary.Hello;
                case nowTime >= 400 && nowTime <= 1129:
                    return this.props.globalState.dictionary.GoodMorning;
                case nowTime >= 1130 && nowTime <= 1759:
                    return this.props.globalState.dictionary.GoodAfternoon;
                default:
                    return this.props.globalState.dictionary.GoodEvening;
            }
        }
    }
    returnQuestionsToShow() {
        let categoryQuestion = this.props.globalState.assistant_state.CategoryQuestion;
        let industryQuestion = this.props.globalState.assistant_state.IndustryQuestion;
        var questionsToShow = [categoryQuestion];

        if (this.props.globalState.current_question_answers[categoryQuestion.QuestionName] !== null) {
            let replacementText = this.props.globalState.current_question_answers[categoryQuestion.QuestionName].answerText;
            questionsToShow.push(this.replaceTokens(industryQuestion, constants.TOKEN_SELECTED_CATEGORY, replacementText));
        }
        return questionsToShow;
    }
    scrollToTarget(target) {
        $(constants.SIDEBAR_ELEMENT).animate({
            scrollTop: target.getBoundingClientRect().top
        }, 500);
    }

    // REDUX ACTIONS
    navigateToContact() {
        this.props.actions.navigateToContactPanel();
        UniversalAnalytics.SendEvent('Convo UI', 'Contact Us Click', 'Contact Us Click', 10);
    }
    engageAssistantWorkflow() {
        this.props.actions.engageAssistantWorkflow();
        UniversalAnalytics.SendEvent('Convo UI', 'Product and Solutions Click', 'Product and Solutions Click', 10);
    }
    resetRecommendations() {
        this.props.actions.resetRecommendations();
        UniversalAnalytics.SendEvent('Convo UI', 'Assistant New Recommendation Click', 'Assistant New Recommendation Click', 10);
    }
    saveAnswerToState(questionName, answerObject) {
        this.props.actions.saveAnswer(questionName, answerObject);
    }
    provideMappedRecommendations() {
        this.props.actions.getRecommendationsFromMappings();
    }
    closeSidebar() {
        this.props.actions.declineAssistance();
        UniversalAnalytics.SendEvent('Convo UI', 'Recent Recommendation Click', 'Recent Recommendation Click', 10);
    }

    // COMPONENT ACTIONS
    inputHasChanged(questionName, answerObject) {
        this.saveAnswerToState(questionName, answerObject);
        this.checkIfShouldProvideMappedRecommendations({ questionName, answerObject });
    }
    checkIfShouldProvideMappedRecommendations(answersUpdate) {
        var shouldProvideMappedRecommendations = true;

        // Update here to access new state w/o waiting for real state to update
        let question_answers = answersUpdate ? Object.assign({}, this.props.globalState.current_question_answers, { [answersUpdate.questionName]: answersUpdate.answerObject }) : this.props.globalState.current_question_answers;

        for (var property in question_answers) {
            if (question_answers.hasOwnProperty(property)) {
                if (question_answers[property] === null) {
                    shouldProvideMappedRecommendations = false;
                }
            }
        }

        if (shouldProvideMappedRecommendations === true) {
            this.provideMappedRecommendations();
        }
    }

    render() {
        // Dom Manipulation
        if (this.props.globalState.showSidebar) {
            this.showSidebar();
        }
        else if (constants.SIDEBAR_ELEMENT.classList.contains('showSidebar')) {
            this.hideSidebar();
        }
        if (this.props.globalState.showContact) {
            this.showContact();
        }
        else if (constants.CONTACT_ELEMENT.classList.contains('showContactPanel')) {
            this.hideContact();
        }

        // Panel State - Return components
        if (this.props.globalState.showAssistant) {
            if (this.props.globalState.showAssistantWorkflow) {
                return (
                    <ConvoUIAssistantPanelWorkflow
                        questionsToShow={this.returnQuestionsToShow()}
                        inputChangeHandler={this.inputHasChanged}
                        AssistantWatermark={this.props.globalState.dictionary.AssistantWatermark}
                        noIndustryText={constants.INDUSTRY_SELECTION_NONE}
                    />
                );
            }

            else if (this.props.globalState.provideMappedRecommendations) {
                return (
                    <ConvoUIAssistantPanelRecommendations
                        data={this.props.globalState.assistant_state}
                        recommendations={this.props.globalState.currentRecommendations}
                        resetRecommendationsHandler={this.resetRecommendations}
                        AssistantWatermark={this.props.globalState.dictionary.AssistantWatermark}
                        resetButtonText={this.props.globalState.dictionary.ResetButtonText}
                        closeSidebarHandler={this.closeSidebar}
                    />
                );
            }

            else if (this.props.globalState.isReturnVisit && this.props.globalState.hasAnsweredQuestionsBefore) {
                return (
                    <ConvoUIAssistantPanelReturn
                        Text={this.props.globalState.assistant_state.Text}
                        greeting={this.determineGreeting()}
                        AssistantWatermark={this.props.globalState.dictionary.AssistantWatermark}
                        resetRecommendationsHandler={this.resetRecommendations}
                        resetButtonText={this.props.globalState.dictionary.ResetButtonText}
                        recommendations={this.props.globalState.currentRecommendations}
                        closeSidebarHandler={this.closeSidebar}
                    />
                );
            }

            else {
                return (
                    <ConvoUIAssistantPanelDefault
                        data={this.props.globalState.assistant_state}
                        engageAssistantWorkflowHandler={this.engageAssistantWorkflow}
                        navigateToContactHandler={this.navigateToContact}
                        greeting={this.determineGreeting()}
                        AssistantWatermark={this.props.globalState.dictionary.AssistantWatermark}
                    />
                );
            }
        }

        else if (this.props.globalState.showRecent) {
            if (this.props.globalState.savedRecommendations.length > 0) {
                return (
                    <ConvoUIRecentPanelPopulated
                        savedRecommendations={this.props.globalState.savedRecommendations}
                        closeSidebarHandler={this.closeSidebar}
                    />
                );
            }
            else {
                return (
                    <ConvoUIRecentPanelInitial
                        data={this.props.globalState.recent_state}
                    />
                );
            }
        }

        // Fallback
        return null;
    }
}

function mapStateToProps(state) {
    return {
        globalState: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvoUIPanelContainer);