/*jshint esversion: 6 */
// Headroom ========================================================= *
import Headroom from 'headroom.js'; // Keep .js extension despite eslint errors b/c this is part of the NPM package name

let editRibbon = document.querySelector('.scWebEditRibbon');

// If anchorbar doesn't exist AND header does exist AND we are not in edit or preview modes
if (!$('.universalanchorbar').length && $("header").length > 0 && !editRibbon) {

    // Set Header to Fixed Position
    $("header").toggleClass("fixed");

    // Init/Options ------------------------
    // grab an element
    var myElement = document.querySelector("header");
    // construct an instance of Headroom, passing the element
    var headroom = new Headroom(myElement, {
        // vertical offset in px before element is first unpinned
        offset: 100,
        // scroll tolerance in px per direction
        tolerance: {
            down: 3,
            up: 3
        }
    });
    // initialise
    headroom.init();

    // after init headroom, set some space in body so header doesn't overlap other elements
    var headerHeight = $('header').height();
    var prevWidth = window.innerWidth;
    $("body").css('padding-top', headerHeight + 'px');
     window.addEventListener('resize', function () {
        if (prevWidth !== window.innerWidth && headerHeight !== $("header").height()) {
            // If window width was updated and height of header has changed,
            // Then update stored header height and update body spacing
            headerHeight = $("header").height();
            $("body").css('padding-top', headerHeight + 'px');
        }
    });
}