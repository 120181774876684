//DOM ELEMENTS
export const INFOGRAPHICS_REACTROOT = document.querySelector('.infographics-reactroot');
export const UNIVERSAL_HEADER = document.querySelector('.universalheader.fixed');

//ACTION TYPES
export const ACTIONS = {
    ACTIVATE_SELECTED_CATEGORY: 'ACTIVATE_SELECTED_CATEGORY',
    ACTIVATE_SELECTED_SUBCATEGORY: 'ACTIVATE_SELECTED_SUBCATEGORY',
    DEACTIVATE_SELECTED_SUBCATEGORY: 'DEACTIVATE_SELECTED_SUBCATEGORY',
    RESET: 'RESET',
    SET_IS_MOBILE: 'SET_IS_MOBILE'
};

//ENUMS
export const LEVEL_ENUM = {
    ONE: 1,
    TWO: 2
};

export const VIDEO_TYPES_ENUM = {
    YOUTUBE: 'Youtube',
    VIMEO: 'Vimeo',
    UPLOAD: 'MP4'
};