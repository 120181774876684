/*jshint esversion: 6 */
// MyProfile Components -------------------------------------- *

let ContactInfoBlockAndForm = function (cibaf) {
    this.cibaf = cibaf;

    this.editButton = this.cibaf.querySelector('.btn-edit');
    this.saveButton = this.cibaf.querySelector('.btn-save');
    this.cancelButton = this.cibaf.querySelector('.btn-cancel');

    this.formFields = this.cibaf.querySelectorAll('.form-group input');
    this.fieldTexts = this.cibaf.querySelectorAll('.form-group span');

    this.firstName = {
        input: this.cibaf.querySelector('#FirstName'),
        label: this.cibaf.querySelector('#FirstNameLabel')
    };

    this.lastName = {
        input: this.cibaf.querySelector('#LastName'),
        label: this.cibaf.querySelector('#LastNameLabel')
    };

    this.department = {
        input: this.cibaf.querySelector('#Department'),
        label: this.cibaf.querySelector('#DepartmentLabel')
    };

    this.title = {
        input: this.cibaf.querySelector('#Title'),
        label: this.cibaf.querySelector('#TitleLabel')
    };

    this.company = {
        input: this.cibaf.querySelector('#Company'),
        label: this.cibaf.querySelector('#CompanyLabel')
    };

    this.phone = {
        input: this.cibaf.querySelector('#Phone'),
        label: this.cibaf.querySelector('#PhoneLabel')
    };

    this.zip = {
        input: this.cibaf.querySelector('#Zip'),
        label: this.cibaf.querySelector('#ZipLabel')
    };

    this.messagesWrapper = this.cibaf.querySelector('.messages');
    this.errorMessage = this.messagesWrapper.querySelector('.alert-danger');
    this.successMessage = this.messagesWrapper.querySelector('.alert-success');
};

ContactInfoBlockAndForm.prototype.Init = function () {
    var _this = this;

    _this.editButton.addEventListener('click', function (e) {
        _this.ToggleEditMode(e);
    });

    _this.cancelButton.addEventListener('click', function (e) {
        _this.ToggleEditMode(e);
    });

    _this.saveButton.addEventListener('click', function (e) {
        _this.Save(e);
    });
};

ContactInfoBlockAndForm.prototype.ToggleEditMode = function (e) {
    e.preventDefault();

    var _this = this;

    _this.editButton.classList.toggle('active');
    _this.saveButton.classList.toggle('active');
    _this.cancelButton.classList.toggle('active');

    _this.successMessage.classList.remove('active');
    _this.messagesWrapper.classList.remove('active');
    _this.errorMessage.classList.remove('active');

    _this.fieldTexts.forEach(function (currentFieldText) {
        currentFieldText.classList.toggle('active');
    });

    _this.formFields.forEach(function (currentFormFields) {
        currentFormFields.classList.toggle('active');
    });
};

ContactInfoBlockAndForm.prototype.Save = function (e) {
    e.preventDefault();

    var _this = this;

    var request = {
        FirstName: _this.firstName.input.value,
        LastName: _this.lastName.input.value,
        Department: _this.department.input.value,
        Title: _this.title.input.value,
        Company: _this.company.input.value,
        Phone: _this.phone.input.value,
        Zip: _this.zip.input.value
    };

    $.post('/universalapi/Feature/MyProfile/MyProfileApi/UpdateProfile',
        request,
        function (response) {
            _this.firstName.label.textContent = _this.firstName.input.value;
            _this.lastName.label.textContent = _this.lastName.input.value;
            _this.title.label.textContent = _this.title.input.value;
            _this.company.label.textContent = _this.company.input.value;
            _this.phone.label.textContent = _this.phone.input.value;
            _this.zip.label.textContent = _this.zip.input.value;

            _this.ToggleEditMode(e);

            _this.successMessage.classList.add('active');
        })
        .done(function () {
            _this.messagesWrapper.classList.add('active');
        })
        .fail(function (response) {
            _this.errorMessage.classList.add('active');
        });
};

window.addEventListener('load', function () {
    let allCIBAFs = document.querySelectorAll('.contact-info-block-and-form');

    if (allCIBAFs) {
        allCIBAFs.forEach(function (currentCIBAF) {

            // Construct new ContactInfoBlockAndForm
            var cibaf = new ContactInfoBlockAndForm(currentCIBAF);

            // Init functionality on load
            cibaf.Init();
        });
    }
});