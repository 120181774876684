/*jshint esversion: 6 */
import ko from 'knockout';

(function () {
    window.jci = window.jci || {};
    var $ = jQuery;

    window.jci.locationFinderViewModel = function (pageSize) {
        this.simpleDistance = function (distance) {
            var decimal = String(distance).indexOf('.');
            return String(distance).substring(0, decimal + 2) + " " + this.page.units;
        };

        this.hasMoreResults = function () {
            return this.$allLocations &&
                this.visibleLocations &&
                this.$allLocations.length > this.visibleLocations().length;
        };

        this.getPageData = function () {
            return this.$allLocations.slice(this.visibleLocations().length, this.visibleLocations().length + this.pageSize);
        };

        this.getPage = function () {
            if (this.hasMoreResults()) {
                var newPage = this.getPageData();

                $(newPage).each(function (index, loc) {
                    this.visibleLocations.push(loc);

                    if (this.page.useGoogle) {
                        this.page.addGoogleMapMarker(loc);
                    }
                }.bind(this));
            }

            this.updateDisplay();
        };
        this.updateDisplay = function () {
            this.showViewMoreButton(this.hasMoreResults());

            $('.location-result-item').each(function (i, element) {
                var $item = $(element);
                var index = $item.attr('index');

                var marker = this.getMarkerByIndex(index);

                if (marker) {
                    $item.on('mouseenter', function (e) {
                        marker.setIcon({
                            url: marker.locationTier === "Preferred" ? "/Include/JCI_com/Local/Images/preferred-icon-highlight.svg" : "/Include/JCI_com/Local/Images/map-pin-icon-highlight.svg",
                            scaledSize: new google.maps.Size(30, 30)
                        });
                        marker.setZIndex(this.zIndex++);
                    });

                    $item.on('mouseleave', function (e) {
                        marker.setIcon({
                            url: marker.locationTier === "Preferred" ? "/Include/JCI_com/Local/Images/preferred-icon.svg" : "/Include/JCI_com/Local/Images/map-pin-icon.svg",
                            scaledSize: new google.maps.Size(30, 30)
                        });
                        marker.setAnimation(null);
                    });
                }
            }.bind(this));

            // this library wasnt keeping up with ko state, so manually refresh it
            $('.selectpicker').selectpicker('refresh');

            this.syncDistance();
        };

        this.getMarkerByIndex = function (index) {
            if (index) {
                return this.page.google.mapMarkers.find(function (marker) {
                    return marker.index === index;
                },
                    this);
            }
        };

        this.pagerString = ko.pureComputed(function () {
            var label = "";
            if (this.visibleLocations && this.$allLocations) {
                label += "<span>Displaying " + this.visibleLocations().length + " of " + this.$allLocations.length + "</span>";
            }

            if (this.page.google.autocomplete && this.page.google.autocomplete.getPlace()) {
                var place = this.page.google.autocomplete.getPlace();
                var address = {};

                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    address[addressType] = place.address_components[i];
                }

                if (address.locality && address.locality.long_name) {
                    label += " location(s) found near <span>" + address.locality.long_name;

                    if (address.administrative_area_level_1 && address.administrative_area_level_1.short_name) {
                        label += ", " + address.administrative_area_level_1.short_name;
                    }

                    if (address.postal_code && address.postal_code.long_name) {
                        label += " " + address.postal_code.long_name;
                    }

                    if (address.country && address.country.short_name) {
                        label += ", " + address.country.short_name;
                    }

                    label += "</span>";
                } else if (address.administrative_area_level_1 && address.administrative_area_level_1.long_name && address.country.long_name) {
                    label += " location(s) found near <span>" + address.administrative_area_level_1.long_name + ', ' + address.country.long_name;

                    label += "</span>";
                } else if (address.country && address.country.long_name) {
                    label += " location(s) found near <span>" + address.country.long_name + "</span>";
                }
            } else {
                label += " locations";
            }

            return label;
        }, this);

        this.applyNewLocations = function (json) {
            this.visibleLocations.removeAll();
            this.preferredVendors.removeAll();
            this.page.clearMapMarkers();

            var $locationsList = $(json);
            this.processNewResults($locationsList);

            if ($locationsList) {
                var preferred = $.grep($locationsList, function (location) {
                    return location.LocationTier === "Preferred";
                }).slice(0, 2);

                this.$allLocations = $.grep($locationsList, function (location) {
                    return $.inArray(location, preferred) < 0;
                }.bind(this)).slice(0, 25);

                this.addStandardLocationsList();
                this.addPreferredVendorsList(preferred);
            }

            this.updateDisplay();
        };

        this.applyClosestPreferredVendors = function (preferred) {
            $(preferred).each(function (index, loc) {
                if (this.page.useGoogle) {
                    this.page.addGoogleMapMarker(loc);
                }

                loc.Distance = this.simpleDistance(loc.Distance);
            }.bind(this));

            this.preferredVendors(preferred);
            this.updateDisplay();
        };

        this.addStandardLocationsList = function () {
            $(this.$allLocations.slice(0, this.pageSize)).each(function (index, loc) {
                this.visibleLocations.push(loc);

                if (this.page.useGoogle) {
                    this.page.addGoogleMapMarker(loc);
                }
            }.bind(this));
        };

        this.addPreferredVendorsList = function (preferred) {
            if (preferred && preferred.length) {
                this.applyClosestPreferredVendors(preferred);
            } else if (this.page.useGoogle || !this.page.useGoogle && this.page.bing.foundLocation) {
                this.page.getLocationResults(this.page.getClosestPreferredVendorRequestUrl(),
                    this.page.getClosestPreferredVendorSuccess);
            }
        };

        this.processNewResults = function ($results) {
            if ($results && $results.length) {
                $results.each(function (index, item) {
                    item.Distance = this.simpleDistance(item.Distance);
                }.bind(this));
            }
        };

        this.openReportForm = function (location) {
            this.clearReportForm();
            this.reportLocation = location;
        }.bind(this);

        this.clearReportForm = function () {
            $('.submit-report-button').removeClass('submitted');
            $('.form-control').attr('disabled', false);
            $('.clear-report-form').attr('disabled', false);
            $('.form-control').val('');
        }.bind(this);

        this.closeReportForm = function () {
            this.clearReportForm();
            this.reportLocation = null;
        }.bind(this);

        this.sendReport = function () {
            var message = $('#incorrectInfoModal' + this.reportLocation.ID + ' .form-control').val();

            var url = this.page.getReportLocationRequestUrl(this.reportLocation.ID, message);

            if (message) {
                var xhr = this.page.createCORSRequest('POST', url);

                if (!xhr) {
                    return;
                }

                xhr.onload = function (response) {
                    this.noPendingReport(true);
                    setTimeout(function () { $('.modal.fade').modal('hide'); }, 1500);
                }.bind(this);

                xhr.onerror = function (response) {
                    alert('CORS failure');
                }.bind(this);

                $('.submit-report-button').addClass('submitted');
                $('.form-control').attr('disabled', true);
                $('.clear-report-form').attr('disabled', true);
                this.noPendingReport(false);
                xhr.send();
            }
        }.bind(this);

        this.init = function (pageSize) {
            this.pageSize = pageSize;
            this.page = jci.locationFinder;
            this.showViewMoreButton = ko.observable(false);
            this.showReportForm = ko.observable(false);
            this.distanceOptions = ko.observableArray(this.page.getDistanceOptions());
            this.selectedDistance = ko.observable();
            this.noPendingReport = ko.observable(true);
            this.useGoogle = this.page.useGoogle;

            this.distanceLabel = function (item) {
                return item.label(this.page.milesLabel);
            }.bind(this);

            this.$allLocations = $([]);
            this.visibleLocations = ko.observableArray();
            this.preferredVendors = ko.observableArray();

            ko.applyBindings(this);

            this.syncDistance();
        };

        this.syncDistance = function () {
            var distance = $(this.distanceOptions()).filter(function (index, item) {
                return item.distance === this.page.distance;
            }.bind(this));

            if (distance && distance.length) {
                this.selectedDistance(distance[0]);
                $('.selectpicker').selectpicker('refresh');
            }
        };

        this.distanceChanged = function () {
            this.page.distance = this.selectedDistance().distance;

            this.page.getLocationResults(this.page.getAllResultsRequestUrl(), this.page.getAllResultsSuccess);

            this.selectedDistance.valueHasMutated();
        };

        this.init(pageSize);
    };
})();