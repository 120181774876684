/*jshint esversion: 6 */

import React from 'react';
import * as constants from '../../../appState/constants';

class ContextPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.shouldRenderPopup()) {
            this.setPopupDimensions();
        }
    }

    shouldRenderPopup() {
        return this.props.navItem.PopupData.Title.Text || this.props.navItem.PopupData.Description ||
            this.props.navItem.PopupData.Link.Text && this.props.navItem.PopupData.Link.Url;
    }

    setPopupDimensions() {
        let spacing = {
            iconPadding: 16,
            iconWidthOrHeight: 30,
            imageMapPadding: 24,
            iconPlusPadding: function () {
                return this.iconWidthOrHeight + this.iconPadding;
            }
        };
        let imageMap = constants.INFOGRAPHICS_REACTROOT.querySelector('.infographics-imagemap-image');
        let popup = constants.INFOGRAPHICS_REACTROOT.querySelector('.infographics-imagemap-contextpopup');

        if (imageMap && popup) {
            let left = `${this.props.navItem.Coordinates.X}% + ${spacing.iconPlusPadding()}px`;
            let top = `${this.props.navItem.Coordinates.Y}% - ${spacing.iconPadding}px`;
            popup.style.left = `calc(${left})`;
            popup.style.top = `calc(${top})`;

            // Check if popup will extend off right of page & correct
            let imageMapRightBoundary = imageMap.getBoundingClientRect().right - spacing.imageMapPadding;
            let popupRightEdge = popup.getBoundingClientRect().right;
            let didOverflowRight = false;
            if (popupRightEdge > imageMapRightBoundary) {
                let overflowDifferencePixels = `${popupRightEdge - imageMapRightBoundary}px`;
                popup.style.left = `calc(${left} - ${overflowDifferencePixels})`;
                popup.style.top = `calc(${this.props.navItem.Coordinates.Y}% + ${spacing.iconPlusPadding()}px)`;
                didOverflowRight = true;
            }

            // Check if popup extends off bottom of page
            let imageMapBottomBoundary = imageMap.getBoundingClientRect().bottom - spacing.imageMapPadding;
            let popupBottomEdge = popup.getBoundingClientRect().bottom;
            if (popupBottomEdge > imageMapBottomBoundary) {
                let overflowDifferencePixels = `${popupBottomEdge - imageMapBottomBoundary}px`;
                if (didOverflowRight) {
                    // If so, need to move popup above button - not just up, because now the popup sits under the button
                    // Moving it up only slightly would hide the button
                    let popupHeight = popup.getBoundingClientRect().height;
                    popup.style.top = `calc(${this.props.navItem.Coordinates.Y}% - ${popupHeight}px - ${spacing.iconPadding}px)`;
                }
                else {
                    // Otherwise, move it up without worrying about the button
                    popup.style.top = `calc(${top} - ${overflowDifferencePixels})`;
                }
            }
        }
    }

    render() {
        return (
            this.shouldRenderPopup() &&
            <div className='infographics-imagemap-contextpopup'>
                {
                    this.props.navItem.PopupData.Title.Text &&
                        this.props.navItem.PopupData.Title.Url ?
                        <a className='infographics-imagemap-contextpopup-titlelink' href={this.props.navItem.PopupData.Title.Url} target='_blank'>
                            {this.props.navItem.PopupData.Title.Text}
                        </a>
                        :
                        <p className='infographics-imagemap-contextpopup-title'>
                            {this.props.navItem.PopupData.Title.Text}
                        </p>
                }

                {
                    this.props.navItem.PopupData.Description &&
                    <p className='infographics-imagemap-contextpopup-description'>
                        {this.props.navItem.PopupData.Description}
                    </p>
                }

                {
                    this.props.navItem.PopupData.Link.Url && this.props.navItem.PopupData.Link.Text &&
                    <a className='infographics-imagemap-contextpopup-link'
                        onClick={() => { infographicsGAHotSpotOverlayClick(this.props.navItem); }}
                        href={this.props.navItem.PopupData.Link.Url} target='_blank'>
                        {this.props.navItem.PopupData.Link.Text}
                    </a>
                }

            </div>
        );
    }
}

function infographicsGAHotSpotOverlayClick(activeData) {
    UniversalAnalytics.SendEvent('Infographics', "Hot Spot Overlay Link Click", activeData.PopupData.Link.Text, 10);
}

export default ContextPopup;