/*jshint esversion: 6 */

let VideoAnchorButton = function (videoAnchorButtonLink) {
    this.videoAnchorButtonLink = videoAnchorButtonLink;
};

VideoAnchorButton.prototype = {
    smoothScroll: function (anchorHref) {
        this.anchoredLinkInPage = document.querySelector(anchorHref);
        if (this.anchoredLinkInPage) {
            var anchorOffset = $(anchorHref).offset().top;
            var anchorHeight = $(anchorHref).height();
            var windowHeight = $(window).height();
            var offset;

            if (anchorHeight < windowHeight) {
                offset = anchorOffset - (windowHeight / 2 - anchorHeight / 2);
            }
            else {
                offset = anchorOffset;
            }
            $('html, body').animate({ scrollTop: offset }, 500);
        }
    }
};

window.addEventListener('load', function () {

    let videoAnchorButtonLink = document.querySelector('.universalvideoanchorbutton-anchor');

    if (videoAnchorButtonLink) {

        $('.universalvideoanchorbutton-anchor').click(function () {
            let anchorHref = this.getAttribute('href');

            if (anchorHref) {
                var videoAnchorButton = new VideoAnchorButton(videoAnchorButtonLink);
                videoAnchorButton.smoothScroll(anchorHref);
            }
        });
    }
});