/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';
import 'owl.carousel2/dist/owl.carousel.min.js';

let TabControl = function (tabControlContainer) {
    this.tabControlContainer = tabControlContainer;
    this.isEditing = tabControlContainer.getAttribute("editing");
    this.owl = this.tabControlContainer.querySelector('.owl-carousel');
    this.panes = this.tabControlContainer.querySelectorAll('.tab-pane');
    this.navLinks = this.tabControlContainer.querySelector('.nav-link');

};

TabControl.prototype = {
    init: function () {
        let _this = this;
        if (this.isEditing === "inexpeditor") {
            this.initEditingPanes();
        } else if (this.owl !== null && this.owl.length !== 0) {
            this.initPanes();
            this.checkSize();
            $(window).resize(function () {
                _this.checkSize();
            });
        }
    },

    initPanes: function () {
        this.showElement(this.panes[0]);
        for (var i = 0; i < this.panes.length; i++) {
            this.panes[i].setAttribute('id', 'tab' + i);
        }
    },

    initEditingPanes: function () {
        for (var i = 0; i < this.panes.length; i++) {
            this.panes[i].setAttribute('id', 'tab' + i);
            this.showElement(this.panes[i]);
        }
    },

    initState: function (event) {
        this.updateStateClasses(event.item.index);
        // Click event
        for (var i = 0; i < this.navLinks.length; i++) {
            this.navLinks[i].addEventListener('click', function () {
                if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
                    this.updateStateClasses(i);
                }
            });
        }
    },

    updateState: function (event) {
        this.updateStateClasses(event.item.index);
    },

    updateStateClasses: function (activeItemIndex) {
        for (var i = 0; i < this.panes.length; i++) {
            this.hideElement(this.panes[i]);
        }
        for (var j = 0; j < this.navLinks.length; j++) {
            this.hideElement(this.navLinks[j]);
        }
        this.showElement(this.panes[activeItemIndex]);
        this.showElement(this.navLinks[activeItemIndex]);
    },

    checkSize: function () {
        let _this = this;
        if ($(window).width() <= 1024) {
            $(this.owl).owlCarousel({
                stagePadding: 35,
                autoHeight: true,
                dots: false,
                items: 1,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    }
                },
                onInitialized: function (e) {
                    _this.initState(e);
                },
                onDragged: function (e) {
                    _this.updateState(e);
                }
            });
            $(this.owl).on('click', '.owl-item', function () {
                $(this).find('li a').attr("class", "nav-link item active show");
                let redirectElementId = $(this).find('li a').attr("href").replace("#", "");
                $(this).siblings().find('li a').removeClass("active show");
                $("#tabContent #" + redirectElementId).attr("class", "tab-pane fade show active");
                $("#tabContent #" + redirectElementId).siblings().removeClass("active show");
            });
        }
        else {
            $(this.owl).owlCarousel('destroy');
        }
    },

    showElement: function (element) {
        if (element) {
            element.classList.add('show');
            element.classList.add('active');
        }
    },

    hideElement: function (element) {
        if (element) {
            element.classList.remove('active');
            element.classList.remove('show');
        }
    }
};

window.addEventListener('load', function () {
    // TabControl
    let tabControlContainers = document.querySelectorAll('.tabcontrol');
    if (tabControlContainers) {
        tabControlContainers.forEach(function (tabControlContainer) {
            // Construct new TabControls
            let tabControl = new TabControl(tabControlContainer);
            tabControl.init();
            console.log(tabControl.innerHTML);
        });
    }
});