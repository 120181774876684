/*jshint esversion: 6 */

import React from 'react';
import ConvoUIQuestion from '../shared/question';

const ConvoUIAssistantPanelWorkflow = (props) => {
    return (
        <div className='universalconvoui-assistant'>
            {
                props.AssistantWatermark &&
                <p className="universalconvoui-assistant-watermark">
                    {props.AssistantWatermark}
                </p>
            }
            {
                props.questionsToShow.length > 0 &&
                <form className='universalconvoui-assistant-form'>
                    {
                        props.questionsToShow.map((question, index) => {
                            let questionIndex = 'questionIndex-' + index;

                            return (
                                <ConvoUIQuestion
                                    key={index}
                                    isFirstQuestion={index === 0 ? true : false}
                                    questionIndex={questionIndex}
                                    questionObject={question}
                                    inputChangeHandler={props.inputChangeHandler}
                                    noIndustryText={props.noIndustryText}
                                />
                            );
                        })
                    }
                </form>
            }
        </div>
    );
};

export default ConvoUIAssistantPanelWorkflow;