/*jshint esversion: 6 */
// MyProfile Components -------------------------------------- *

let BusinessQuestionsBlockAndForm = function (bqbaf) {
    this.bqbaf = bqbaf;

    this.editButton = this.bqbaf.querySelector('.btn-edit');
    this.saveButton = this.bqbaf.querySelector('.btn-save');
    this.cancelButton = this.bqbaf.querySelector('.btn-cancel');

    this.dropdowns = this.bqbaf.querySelectorAll('select');
    this.selectedOptions = this.bqbaf.querySelectorAll('.selected-option');
    this.fieldTexts = this.bqbaf.querySelectorAll('.selected-option');

    this.messagesWrapper = this.bqbaf.querySelector('.messages');
    this.errorMessage = this.messagesWrapper.querySelector('.alert-danger');
    this.successMessage = this.messagesWrapper.querySelector('.alert-success');
};

BusinessQuestionsBlockAndForm.prototype.Init = function () {
    var _this = this;

    _this.editButton.addEventListener('click', function (e) {
        _this.ToggleEditMode(e);
    });

    _this.cancelButton.addEventListener('click', function (e) {
        _this.ToggleEditMode(e);
    });

    _this.saveButton.addEventListener('click', function (e) {
        _this.Save(e);
    });

    _this.dropdowns.forEach(function (currentDropdown, ddIndex) {
        var targetOptionValue = _this.selectedOptions[ddIndex];

        currentDropdown.value = targetOptionValue.dataset.selectedanswerid;
    });
};

BusinessQuestionsBlockAndForm.prototype.ToggleEditMode = function (e) {
    e.preventDefault();

    var _this = this;

    _this.successMessage.classList.remove('active');
    _this.messagesWrapper.classList.remove('active');
    _this.errorMessage.classList.remove('active');

    _this.editButton.classList.toggle('active');
    _this.saveButton.classList.toggle('active');
    _this.cancelButton.classList.toggle('active');

    _this.fieldTexts.forEach(function (currentFieldText) {
        currentFieldText.classList.toggle('active');
    });

    _this.dropdowns.forEach(function (currentFormFields) {
        currentFormFields.classList.toggle('active');
    });
};

BusinessQuestionsBlockAndForm.prototype.Save = function (e) {
    e.preventDefault();

    var _this = this;

    var selectedAnswers = [];

    _this.dropdowns.forEach(function (currentDropdown) {
        var obj = {
            QuestionId: currentDropdown.dataset.questionid,
            SelectedAnswerId: currentDropdown.value,
            SelectedAnswerText: currentDropdown.options[currentDropdown.selectedIndex].text
        };

        selectedAnswers.push(obj);
    });

    var request = {
        SelectedAnswers: selectedAnswers
    };

    $.post('/universalapi/Feature/MyProfile/MyProfileApi/SetBusinessQuestions',
        request,
        function (response) {
            _this.ToggleEditMode(e);

            _this.successMessage.classList.add('active');

            _this.selectedOptions.forEach(function (currentSelectedOption, index) {
                currentSelectedOption.textContent = request.SelectedAnswers[index].SelectedAnswerText;
            });
        })
        .done(function () {
            _this.messagesWrapper.classList.add('active');
        })
        .fail(function (response) {
            _this.errorMessage.classList.add('active');
        });
};

window.addEventListener('load', function () {
    let allBQBAFs = document.querySelectorAll('.business-questions-block-and-form');

    if (allBQBAFs) {
        allBQBAFs.forEach(function (currentBQBAF) {

            // Construct new BusinessQuestionsBlockAndForm
            var bqbaf = new BusinessQuestionsBlockAndForm(currentBQBAF);

            // Init functionality on load
            bqbaf.Init();
        });
    }
});