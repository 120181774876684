/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let LocationFinderCallout = function (calloutComponent) {
    this.calloutComponent = calloutComponent;
    this.invalidAddressText = $(calloutComponent).data('invalidaddresstext');
    this.resultsUrl = $(calloutComponent).data('resultsurl');
    this.units = $(calloutComponent).data('units');
    this.autocompleteId = $(calloutComponent).data('autocompleteid');
    this.useGoogle = $(calloutComponent).data('usegoogle');
    this.bingApiKey = $(calloutComponent).data('bingapikey');
};

LocationFinderCallout.prototype = {
    initialize: function () {
        jci.locationFinderConstants.init(this.invalidAddressText);
        jci.userLocation.init(this.resultsUrl, this.units, this.autocompleteId, this.useGoogle, this.bingApiKey, false);
    }
};

window.addEventListener('load', function () {
    let allCallouts = document.querySelectorAll('.universallocationfindercallout');
    if (allCallouts) {
        allCallouts.forEach(function(callout) {
            // Construct new LocationFinderCallout
            var locationFinderCallout = new LocationFinderCallout(callout);
            locationFinderCallout.initialize();
        });
    }
});