export function getData(infographicsItemId, itemlanguage) {
    var jqXHR = $.ajax({
        url: '/universalapi/Feature/Components/Infographics/GetInfographicsData',
        type: 'GET',
        data: { infographicsItemId: infographicsItemId, language: itemlanguage },
        async: false
    });

    if (jqXHR.status === 200 && jqXHR.readyState === 4) {
        let parsedResponse = JSON.parse(jqXHR.responseText);

        if ($.isEmptyObject(parsedResponse)) {
            throw 'Error: Empty object returned from GetInfographicsData, though GET request was successful. Make sure Sitecore items are configured correctly.';
        }
        else {
            return parsedResponse;
        }
    }
    else {
        console.log('fail');
        throw `Error fetching Infographics data. Status code: ${jqXHR.status}`;
    }
}