/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';
import { NavOverflow } from './navOverflow';

let AnchorBar = function (anchorBarContainer) {
    this.anchorBarContainer = anchorBarContainer;
    this.anchorBarHeader = this.anchorBarContainer.querySelector('.universalanchorbar-header');
    this.anchorBar = this.anchorBarContainer.querySelector('.universalanchorbar-container');
    this.anchorLinkItems = this.anchorBarContainer.querySelectorAll('.universalanchorbar-anchors-listitem');
    this.firstAnchorLink = this.anchorLinkItems[0].querySelector('.universalanchorbar-anchors-listitem-link');
    this.mobileDropdownToggle = this.anchorBarContainer.querySelector('.universalanchorbar-anchors-mobiletoggle');
    this.pageTitleAnchor = this.anchorBarContainer.querySelector('.universalanchorbar-pagetitle-link');

    this.anchorBarFullHeight = function () {
        return this.anchorBar.getBoundingClientRect().height;
    };
};

AnchorBar.prototype = {
    // Fix anchor bar to top of page when scrolling past its static position
    setStickiness: function () {
        this.anchorBarTop = this.anchorBarContainer.getBoundingClientRect().top;

        if (this.anchorBarTop < 0) {
            document.body.classList.add('anchorBarIsFixed');
            this.anchorBarContainer.style.height = this.anchorBarFullHeight() + 'px';

        } else {
            document.body.classList.remove('anchorBarIsFixed');
            this.anchorBarContainer.style.height = 'auto';
        }
    },

    // When clicking on anchor bar link, scroll to selected anchor in page
    // The distance will vary based on screen size and whether the anchor bar is fixed
    smoothScroll: function (anchorHref) {
        this.anchoredLinkInPage = document.querySelector(anchorHref);
        if (this.anchoredLinkInPage) {
            this.amountToScroll = this.anchorBarFullHeight();

            if (window.innerWidth <= 1024 && document.body.classList.contains('anchorBarIsFixed')) {
                // If mobile AND anchorbar is fixed, only need to scroll height of anchorbar header - not full
                this.amountToScroll = this.anchorBarHeader.clientHeight;
            }

            $('html, body').animate({
                scrollTop: $(anchorHref).offset().top - this.amountToScroll
            }, 500);
        }
    },

    // Toggle mobile anchor dropdown on click
    toggleMobileDropdown: function () {
        document.body.classList.toggle('mobileAnchorBarIsOpen');
    },

    // Update mobile anchor dropdown when anchor is selected
    updateMobileDropdown: function (anchorLink) {
        this.mobileDropdownToggle.textContent = anchorLink.textContent;
        document.body.classList.remove('mobileAnchorBarIsOpen');
    }
};

window.addEventListener('load', function () {
    // AnchorBar
    let anchorBarContainer = document.querySelector('.universalanchorbar');
    if (anchorBarContainer) {
        // Construct new AnchorBar
        var anchorBar = new AnchorBar(anchorBarContainer);

        // Init functionality on load
        anchorBar.setStickiness();
        anchorBar.mobileDropdownToggle.addEventListener('click', anchorBar.toggleMobileDropdown);
        anchorBar.mobileDropdownToggle.textContent = anchorBar.firstAnchorLink.textContent;

        // Click events
        anchorBar.anchorLinkItems.forEach(function (anchorLinkItem) {
            let anchorLink = anchorLinkItem.querySelector('.universalanchorbar-anchors-listitem-link');
            if (anchorLink) {
                let anchorHref = anchorLink.getAttribute('href');
                //Add Event if anchor is in other page
                var host = window.location.origin;
                if (anchorHref.includes(host) && anchorHref.includes("#")) {
                    anchorLinkItem.addEventListener('click', function (e) {
                        location.href = anchorHref;
                    });
                }
                //Add Event only if anchor
                var isUrl = anchorHref.includes("#");
                if (isUrl) {
                    if (anchorHref) {
                        anchorLinkItem.addEventListener('click', function (e) {
                            e.preventDefault();
                            history.pushState({}, $('title').text(), anchorHref);
                            anchorBar.smoothScroll(anchorHref);
                            if (window.innerWidth <= 767) {
                                anchorBar.updateMobileDropdown(anchorLink);
                            }
                        });
                    }
                }
            }
        });
        anchorBar.pageTitleAnchor.addEventListener('click', function (e) {
            e.preventDefault();
            navOverflow.hideOverflowLinks();
            $("html, body").animate({ scrollTop: 0 }, 500);
            return false;
        });

        // Scroll events
        window.addEventListener('scroll', function () {
            anchorBar.setStickiness();
        });

        // Resize events
        window.addEventListener('resize', function () {
            anchorBar.setStickiness();
        });
    }

    // NavOverflow
    let nav = document.querySelector('.universalanchorbar-anchors-nav');
    if (nav) {
        // Construct new NavOverflow
        var navOverflow = new NavOverflow(nav);

        // Init functionality on load
        navOverflow.runOverflowCheck();
    }
});

$(window).on('popstate', function (e) {
    location.reload();
});