var UniversalAnalytics = function () {
};

UniversalAnalytics.TrackerName = "";
UniversalAnalytics.DetectGoogleMaxAttempts = 10;
UniversalAnalytics._checkGaAttempts = 0;
UniversalAnalytics._isGaDefined = false;

UniversalAnalytics.DetectGoogleAnalytics = function () {
    if (UniversalAnalytics._checkGaAttempts > UniversalAnalytics.DetectGoogleMaxAttempts) {
        console.log("ga is undefined");
        return;
    }
        
    UniversalAnalytics._checkGaAttempts++;

    if (typeof ga === 'function') {
        console.log('Loaded :' + ga);
        UniversalAnalytics._isGaDefined = true;
        UniversalAnalytics.SetUniversalTrackerName();
    } else {
        console.log('Not loaded');
        setTimeout(UniversalAnalytics.DetectGoogleAnalytics, 500);
    }
}

UniversalAnalytics.SetUniversalTrackerName = function () {
    ga(function () {
        var trackers = ga.getAll();
        if (trackers.length === 0) {
            ga('create', 'UA-XXXXX-Y', 'auto');
        }
        else {
            for (var i = 0; i < trackers.length; ++i) {
                var tracker = trackers[i];
                var trackerName = tracker.get('name');
                UniversalAnalytics.TrackerName = trackerName;
            }
        }
    });
}

UniversalAnalytics.SendEvent = function (eventCategory, eventAction, eventLabel, eventValue) {
    if (eventCategory === "" || eventAction === "" || eventLabel === "") {
        console.log("Send Event skipped.  Category/Action/Label is not populated.");
        return;
    }

    if (UniversalAnalytics._isGaDefined) {
        var sendTracker = UniversalAnalytics._getTrackerName();
        console.log("analytics event: " + sendTracker + ":" + eventCategory + ":" + eventAction + ":" + eventLabel + ":" + eventValue);
        ga(sendTracker, {
            'hitType': 'event',
            'eventCategory': eventCategory,
            'eventAction': eventAction,
            'eventLabel': eventLabel,
            'eventValue': eventValue
        });
    }
}

UniversalAnalytics._getTrackerName = function () {
    if (UniversalAnalytics.TrackerName === "") {
        return "send";
    }
    else {
        return UniversalAnalytics.TrackerName + ".send";
    }
}

window.UniversalAnalytics = UniversalAnalytics;

window.addEventListener('load', function () {
    UniversalAnalytics.DetectGoogleAnalytics();
});

