/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';
import 'owl.carousel2/dist/owl.carousel.min.js';

let InsightQuilt = function (insightQuilt) {
    this.insightQuilt = insightQuilt;
    this.insightCards = this.insightQuilt.querySelectorAll('.universalinsightquilt-card:not(.universalinsightquilt-card-accent)');
    this.insightCardsCount = this.insightsCards !== null ? this.insightCards.length : 0;
    this.carouselIsRequired = this.insightCardsCount === 2;
    this.carouselExists = false;
};

InsightQuilt.prototype = {
    initQuilt: function () {
        if (this.carouselIsRequired) {
            this.createOrDestroyCarousel();
        }
        this.initResizeFunctionality();
    },

    createOrDestroyCarousel: function () {
        if (window.innerWidth <= 767 && !this.carouselExists) {
            $(this.insightCards).wrapAll('<div class="universalinsightquilt-carousel owl-carousel"></div>');
            $(".universalinsightquilt-carousel").owlCarousel({
                autoHeight: true,
                dots: false,
                items: 1,
                margin: 24,
                stagePadding: 24
            });
            this.carouselExists = true;
        } else if (window.innerWidth > 767 && this.carouselExists) {
            $(".universalinsightquilt-carousel").owlCarousel('destroy');
            $(this.insightCards).unwrap();
            this.carouselExists = false;
        }
    },

    initResizeFunctionality: function () {
        let _this = this;
        window.addEventListener('resize', function () {
            if (_this.carouselIsRequired) {
                _this.createOrDestroyCarousel();
            }
        });
    }
};

// Init
window.addEventListener('load', function () {
    let insightQuilts = document.querySelectorAll('.universalinsightquilt');
    if (insightQuilts) {
        // Construct new Insight Quilts
        insightQuilts.forEach(function (insightQuiltDiv) {
            let insightQuilt = new InsightQuilt(insightQuiltDiv);
            insightQuilt.initQuilt();
        });
    }
});