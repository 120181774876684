var searchInterface = $('.CoveoSearchInterface');
var eventgrid = $('.event-grid');

if (searchInterface && searchInterface.length && eventgrid && eventgrid.length) {
    $("#" + searchInterface[0].id).on('newResultsDisplayed', function () {
        $('.coveo-result-list-container').each(function (index, container) {
            $(container).addClass('row');
        });

        $('.CoveoResult').each(function (index, item) {
            $(item).addClass('col-md-4');
        });

        if ($(".coveo-no-results")[0]) {
            $("div.coveo-no-results").hide();
            $("button.filters").hide();
        } else {
            $("div.coveo-no-results").show();
            $("button.filters").show();
        }

    });
}

// Toggle Filter Facets
$(".event-grid .filters").click(function (e) {
    e.preventDefault();
    $(".coveo-facet-column").show();
});

$(".event-grid .close").click(function (e) {
    e.preventDefault();
    $(".coveo-facet-column").hide();
});