/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';

let SocialShare = function (socialShare) {
    this.socialShare = socialShare;
    this.allLinks = this.socialShare.querySelectorAll('.iconlink');
    this.loc = encodeURI(window.location);
};

SocialShare.prototype = {
    init: function () {
        let _this = this;
        if (_this && _this.socialShare) {
            var changehref = _this.socialShare.getAttribute("href") + this.loc;
            _this.socialShare.setAttribute("href", changehref);
        }
    }
};

window.addEventListener('load', function () {
    var loc = window.location;
    let socialShareIcons = document.querySelectorAll('.iconlink');
    if (socialShareIcons) {
        socialShareIcons.forEach(function (socialShareIcon) {
            var newSocialShare = new SocialShare(socialShareIcon);
            newSocialShare.init();
        });
    }
});