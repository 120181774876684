/*jshint esversion: 6 */
// MyProfile Components -------------------------------------- *

let RegistrationForm = function (regForm) {
    this.regForm = regForm;

    this.registerButton = this.regForm.querySelector('.btn-register');

    this.tycoCheckbox = this.regForm.querySelector('#IsTycoIntegratedSecurityCustomer');
    this.dataSourceCheckbox = this.regForm.querySelector('.datasource-checkbox');

    this.registrationSuccess = document.querySelector('.registration-success');

    this.firstName = {
        input: this.regForm.querySelector('#FirstName')
    };

    this.lastName = {
        input: this.regForm.querySelector('#LastName')
    };

    this.company = {
        input: this.regForm.querySelector('#Company')
    };

    this.phone = {
        input: this.regForm.querySelector('#Phone')
    };

    this.zipcode = {
        input: this.regForm.querySelector('#Zipcode')
    };

    this.email = {
        input: this.regForm.querySelector('#Email')
    };

    this.isTycoIntegratedSecurityCustomer = {
        input: this.regForm.querySelector('#IsTycoIntegratedSecurityCustomer')
    };

    this.enrollInDataSource = {
        input: this.regForm.querySelector('#EnrollInDataSource')
    };
};

RegistrationForm.prototype.Init = function () {
    var _this = this;

    _this.registerButton.addEventListener('click', function (e) {
        _this.Register(e);
    });

    _this.tycoCheckbox.addEventListener('change', function (e) {
        _this.ToggleDataSourceCheckbox(e);
    });
};

RegistrationForm.prototype.ToggleDataSourceCheckbox = function (e) {
    e.preventDefault();

    var _this = this;

    _this.dataSourceCheckbox.classList.toggle('active');
};

RegistrationForm.prototype.Register = function (e) {
    e.preventDefault();

    var _this = this;

    var request = {
        FirstName: _this.firstName.input.value,
        LastName: _this.lastName.input.value,
        Company: _this.company.input.value,
        Phone: _this.phone.input.value,
        Zipcode: _this.zipcode.input.value,
        IsTycoIntegratedSecurityCustomer: _this.isTycoIntegratedSecurityCustomer.input.checked,
        Email: _this.email.input.value
    };

    if (_this.isTycoIntegratedSecurityCustomer.input.checked) {
        request.EnrollInDataSource = _this.enrollInDataSource.input.checked;
    }

    $.post('/universalapi/Feature/MyProfile/MyProfileApi/CreateProfile', request)
        .always(function (response) {
            _this.regForm.classList.remove('active');
            _this.registrationSuccess.classList.add('active');
        });
};

window.addEventListener('load', function () {
    let allRegForms = document.querySelectorAll('.registration-form');

    if (allRegForms) {
        allRegForms.forEach(function (currentRegForm) {

            // Construct new RegistrationForm
            var regForm = new RegistrationForm(currentRegForm);

            // Init functionality on load
            regForm.Init();
        });
    }
});