/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let ProductCardList = function (productCardListEl) {
    this.productCardListEl = productCardListEl;
    this.productCards = this.productCardListEl.querySelectorAll('.productcard');
};

ProductCardList.prototype = {
    init: function () {
        var _this = this;
        if (_this.productCards !== null && _this.productCards.length > 0
            && window.matchMedia("(any-hover: hover)").matches) { // If device is capable of hovering
            _this.productCards.forEach(function (productCard) {
                let productCardContent = productCard.querySelector('.productcardcontent');

                productCardContent.addEventListener('mouseenter', _this.setHoverHeight);
                productCardContent.addEventListener('mouseleave', _this.resetVisibleHeight);
            });
        }
    },

    setHoverHeight: function () {
        this.style.height = `${this.scrollHeight}px`;
    },

    resetVisibleHeight: function () {
        this.style.height = "";
    }
};

window.addEventListener('load', function () {
    let allProductCardLists = document.querySelectorAll('.productcardlist');
    if (allProductCardLists) {
        allProductCardLists.forEach(function (productCardListEl) {
            let productCardList = new ProductCardList(productCardListEl);
            productCardList.init();
        });
    }
});