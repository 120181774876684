/*jshint esversion: 6 */

import React from 'react';

const ConvoUIRecentPanelInitial = (props) => {
    return (
        <div className="universalconvoui-recent">
            {
                props.data.Text &&
                <p className="universalconvoui-recent-text">
                    {props.data.Text}
                </p>
            }
        </div>
    );
};

export default ConvoUIRecentPanelInitial;