/*jshint esversion: 6 */
import 'nodelist-foreach-polyfill';
import 'owl.carousel2/dist/owl.carousel.min.js';

let UniversalBrandBar = function (allUniversalBrandBar) {
    // BrandBar elements
    this.universalBrandBar = allUniversalBrandBar;
    this.carouselElement = this.universalBrandBar.querySelector('.owl-carousel');
    this.numberOfBrands = this.universalBrandBar.querySelectorAll('.universalbrandbar-brand').length;
    this.brandBarButton = this.universalBrandBar.querySelector('.universalbrandbar-btn');

    // Other DOM elements
    this.desktopUtilityBrandBarButton = document.querySelector('.desktop .utilitybrandbar .brand-wrap .accordionItemHeading');
    this.mobileUtilityBrandBarButton = document.querySelector('#fsNav .utilitybrandbar .brand-wrap .accordionItemHeading');
    this.mobileNavOverlay = document.querySelector('#fsNav');
    this.pageHeader = document.querySelector('.universalheader');
    this.mobileMenuButton = document.querySelector('.openNav');

    // Data storage
    this.headroomEnabled = this.pageHeader.classList.contains('headroom'); // Page header is using headroom.js to keep header fixed in some cases
};

UniversalBrandBar.prototype.Init = function () {
    var _this = this;

    if (_this) {
        // Init carousel for brands, desktop + mobile
        if (_this.carouselElement) {
            _this.ConfigureCarousel(_this);
            window.addEventListener('resize', function () {
                _this.ConfigureCarousel(_this);
            });
        }

        // Click event for button
        if (_this.brandBarButton) {
            _this.brandBarButton.addEventListener('click', function (e) {
                e.preventDefault();
                _this.OpenUtilityBrandBar(_this);
            });
        }
    }
};

UniversalBrandBar.prototype.OpenUtilityBrandBar = function (_this) {
    // Desktop
    if (window.innerWidth > 1024 && _this.desktopUtilityBrandBarButton) {
        if (_this.headroomEnabled && _this.pageHeader) {
            // If header follows user on page, is fixed, the Utility Brand Bar button is positioned dynamically
            // This takes care of some buggy behavior w / 'headroom.js'
            _this.pageHeader.style.transition = 'none';
            _this.pageHeader.classList.remove('headroom--unpinned');
            _this.pageHeader.classList.add('headroom--pinned');
        }
        else {
            // Else if subnav exists, scroll to top of page where Utility Brand Bar button lives permanently
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
        // Simulate 'click' on Desktop Utility Brand Bar button
        _this.desktopUtilityBrandBarButton.click();

        // Reset transition
        if (_this.headroomEnabled) {
            _this.pageHeader.style.transition = '';
        }
    }

    // Mobile
    else if (_this.mobileNavOverlay && _this.mobileUtilityBrandBarButton) {
        // Simulate 'click' on mobile menu button
        _this.mobileMenuButton.click();
        // Simulate 'click' on Mobile Utility Brand Bar button
        _this.mobileUtilityBrandBarButton.click();
        // Scroll to mobile menu brands
        setTimeout(function () {
            $(_this.mobileNavOverlay).animate({
                scrollTop: $(_this.mobileUtilityBrandBarButton).offset().top
            }, 500);
        }, 500);
    }
};

UniversalBrandBar.prototype.CalcNumberToShow = function (defaultNumberToShow, minNumberToSpread, _this) {
    // If total number of brands is < defaultNumber, show total instead of default
    // Otherwise default will show blank space where 'missing' brands would be
    // Min number makes sure there's at least X number of brands to show, otherwise spacing would be odd
    // More useful for larger screens
    if (_this.numberOfBrands < defaultNumberToShow && _this.numberOfBrands >= minNumberToSpread) {
        return _this.numberOfBrands;
    } else {
        return defaultNumberToShow;
    }
};

UniversalBrandBar.prototype.ConfigureCarousel = function (_this) {
    $(_this.carouselElement).owlCarousel({
        nav: true,
        navText: [],
        responsive: {
            0: {
                items: 2
            },
            400: {
                items: 3
            },
            500: {
                items: 4 // We get more space at <=600px, can show more logos
            },
            600: {
                items: 3
            },
            700: {
                items: 4
            },
            800: {
                items: _this.CalcNumberToShow(5, 3, _this)
            },
            900: {
                items: _this.CalcNumberToShow(6, 3, _this)
            },
            1000: {
                items: _this.CalcNumberToShow(7, 4, _this)
            }
        }
    });
};

window.addEventListener('load', function () {
    let allUniversalBrandBars = document.querySelectorAll('.universalbrandbar');
    if (allUniversalBrandBars) {
        allUniversalBrandBars.forEach(function (allUniversalBrandBar) {
            // Construct new BrandBar
            var universalBrandBar = new UniversalBrandBar(allUniversalBrandBar);

            // Init functionality on load
            universalBrandBar.Init();
        });
    }
});
