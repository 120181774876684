/*jshint esversion: 6 */

import 'nodelist-foreach-polyfill';

let ExpandableCallout = function (expandableCallout) {
    this.expandableCallout = expandableCallout;
    this.button = this.expandableCallout.querySelector('.universalexpandablecallout-button');
    this.linksAreVisible = false;
};

ExpandableCallout.prototype = {
    // Click events
    initClickFunctionality: function () {
        if (this.button == null) {
            return;
        }

        let _this = this;
        if (this.button) {
            this.button.addEventListener('click', function () {
                _this.expandableCallout.classList.toggle('linksAreHidden');
                _this.linksAreVisible = !_this.linksAreVisible;
                if (_this.linksAreVisible === true) {
                    this.innerText = _this.button.getAttribute('data-hidetext');
                } else {
                    this.innerText = _this.button.getAttribute('data-expandtext');
                }
            });
        }
    }
};



// Init
let expandableCallouts = document.querySelectorAll('.universalexpandablecallout');
if (expandableCallouts) {
    // Construct new Expandable Callouts
    expandableCallouts.forEach(function (expandableCallout) {
        let exCall = new ExpandableCallout(expandableCallout);
        exCall.initClickFunctionality();
    });
}