import * as constants from './constants';
import * as services from './services';

// Get Data
let data = services.getData();

if (data != null)
{
    // Data Variables
    let dictionaryNode = data['Dictionary'];
    let assistantDataNode = data['Assistant Data'];
    let promptDataNode = data['Prompt Data'];
    let recentDataNode = data['Recent Data'];
    let mappedRecommendationsDataNode = data['Mapped Recommendations Data'];
    let defaultMappedRecommendationsDataNode = data['Default Recommendations Cards'];
    let categoryQuestionName = assistantDataNode.AssistantWorkflowState.CategoryQuestion.QuestionName;
    let industryQuestionName = assistantDataNode.AssistantWorkflowState.IndustryQuestion.QuestionName;

    // Local Storage
    let initialState = services.getFromLocalStorage(constants.CONVO_UI_STATE) ||
        {
            // State
            isReturnVisit: false,
            hasAnsweredQuestionsBefore: false,

            // Prompt Actions
            hasDeclined: false,
            showSidebar: false,
            checkingIn: false,
            hasDeclinedCheckIn: false,

            // Nav Actions
            showAssistant: false,
            showContact: false,
            showRecent: false,

            // Nav State Forks        
            showAssistantWorkflow: false,
            provideMappedRecommendations: false,
            currentRecommendations: [],

            // User Workflow Answers
            current_question_answers: {
                [categoryQuestionName]: null,
                [industryQuestionName]: null
            },
            archived_question_answers: [
            ],

            // Data
            dictionary: dictionaryNode,
            assistant_state: assistantDataNode.AssistantInitialState,
            prompt_state: promptDataNode.PromptInitialState,
            recent_state: recentDataNode.RecentInitialState,

            // Items that appear in Recent tab
            savedRecommendations: []
        };

    // Session Storage (reset some 'initial state' properties for return visits)
    if (services.isReturnVisit(constants.CONVO_UI_STATE, constants.CONVO_UI_SESSION)) {
        initialState = Object.assign({}, initialState,
            {
                isReturnVisit: true,
                hasDeclined: false,
                hasDeclinedCheckIn: false,
                checkingIn: false,
                showSidebar: false,
                showAssistant: false,
                showContact: false,
                showRecent: false,
                showAssistantWorkflow: false,
                provideMappedRecommendations: false,
                currentRecommendations: [],
                prompt_state: promptDataNode.PromptReturnState,
                assistant_state: initialState.hasAnsweredQuestionsBefore ? assistantDataNode.AssistantReturnState : assistantDataNode.AssistantInitialState
            });
    }

    if (!services.sessionExists(constants.CONVO_UI_SESSION)) {
        services.initNewSession(constants.CONVO_UI_SESSION);
    }

    // Functions (Purpose: Shared across components which do not share a parent component. Use info from state to inform components. These do NOT update state directly.)
    initialState.shouldSearchForRecommendations = function () {
        // Should search for recommendations / return true if:
        // User is returning && user has answered questions before && no current recommendations are displayed & user is not engaged in assistant workflow
        return this.isReturnVisit
            && this.hasAnsweredQuestionsBefore
            && this.currentRecommendations.length === 0
            && !this.showAssistantWorkflow;
    };
}

// Reducer
export default function convoUiReducer(state = initialState, action) {
    let updatedState = state;

    if (action.type === constants.DECLINE_ASSISTANCE) {
        updatedState = Object.assign({},
            state, {
                hasDeclined: true,
                showSidebar: false,
                checkingIn: false,
                prompt_state: promptDataNode.PromptRestingState
            });
    }

    else if (action.type === constants.DECLINE_CHECK_IN) {
        updatedState = Object.assign({},
            state, {
                hasDeclined: true,
                hasDeclinedCheckIn: true,
                checkingIn: false,
                prompt_state: promptDataNode.PromptRestingState
            });
    }

    else if (action.type === constants.CHECK_IN) {
        updatedState = Object.assign({},
            state, {
                hasDeclined: false,
                checkingIn: true,
                prompt_state: promptDataNode.PromptCheckingInState
            });
    }

    else if (action.type === constants.ENGAGE_ASSISTANT_WORKFLOW) {
        updatedState = Object.assign({},
            state, {
                showAssistantWorkflow: true,
                assistant_state: assistantDataNode.AssistantWorkflowState
            });
    }

    else if (action.type === constants.NAV_TO_CONTACT) {
        updatedState = Object.assign({},
            state, {
                checkingIn: false,
                showAssistant: false,
                showContact: true,
                showRecent: false,
                showSidebar: true
            });
    }

    else if (action.type === constants.NAV_TO_RECENT) {
        updatedState = Object.assign({},
            state, {
                checkingIn: false,
                showAssistant: false,
                showContact: false,
                showRecent: true,
                showSidebar: true
            });
    }

    else if (action.type === constants.NAV_TO_ASSISTANT) {
        updatedState = Object.assign({},
            state, {
                checkingIn: false,
                hasDeclined: false,
                hasDeclinedCheckIn: false,
                showAssistant: true,
                showContact: false,
                showRecent: false,
                showSidebar: true
            });
    }

    else if (action.type === constants.PROVIDE_MAPPED_RECOMMENDATIONS) {
        let categoryAnswer = state.current_question_answers[categoryQuestionName];
        let industryAnswer = state.current_question_answers[industryQuestionName];
        let mappedRecommendations = services.getMappedRecommendations(mappedRecommendationsDataNode, defaultMappedRecommendationsDataNode, categoryAnswer.answerText, industryAnswer.answerText);
        let updatedSavedRecommendations = services.updateSavedRecommendations(state.savedRecommendations, mappedRecommendations);
        let updatedArchivedAnswers = state.archived_question_answers.slice();
        updatedArchivedAnswers.push({ [constants.CATEGORY]: categoryAnswer, [constants.INDUSTRY]: industryAnswer });

        updatedState = Object.assign({},
            state, {
                showAssistantWorkflow: false,
                provideMappedRecommendations: true,
                hasAnsweredQuestionsBefore: true,
                currentRecommendations: mappedRecommendations,
                archived_question_answers: updatedArchivedAnswers,
                assistant_state: assistantDataNode.AssistantRecommendationsState,
                savedRecommendations: updatedSavedRecommendations
            });
    }

    else if (action.type === constants.RESET_RECOMMENDATIONS) {
        updatedState = Object.assign({},
            state, {
                showAssistantWorkflow: true,
                provideMappedRecommendations: false,
                current_question_answers: {
                    [categoryQuestionName]: null,
                    [industryQuestionName]: null
                },
                currentRecommendations: [],
                assistant_state: assistantDataNode.AssistantWorkflowState
            });
    }

    else if (action.type === constants.SAVE_ANSWER) {
        let answers = Object.assign({}, state.current_question_answers, { [action.questionName]: action.answerObject });
        let guidToSave = action.answerObject.answerGuid;
        let facetName = action.questionName.toLowerCase();

        if (facetName && guidToSave) {
            services.postSearchFacets(facetName, guidToSave);
        }

        updatedState = Object.assign({},
            state, {
                current_question_answers: answers
            });
    }

    else if (action.type === constants.PROVIDE_SEARCH_RECOMMENDATIONS) {
        // Get current answers
        var categoryAnswer = state.current_question_answers[categoryQuestionName];
        var industryAnswer = state.current_question_answers[industryQuestionName];

        if (!categoryAnswer || !industryAnswer) {
            // If user cleared their answers, we can use the latest ones that were archived
            let archivedAnswers = state.archived_question_answers;
            let indexOfLastArchivedAnswer = archivedAnswers.length - 1;
            let latestAnswer = archivedAnswers[indexOfLastArchivedAnswer];
            categoryAnswer = latestAnswer[constants.CATEGORY];
            industryAnswer = latestAnswer[constants.INDUSTRY];
        }

        // Use services to get and update recommendations:
        let searchedRecommendations = services.getSearchRecommendations(categoryAnswer.answerGuid, industryAnswer.answerGuid);
        let updatedSavedRecommendations = services.updateSavedRecommendations(state.savedRecommendations, searchedRecommendations);

        updatedState = Object.assign({},
            state, {
                currentRecommendations: searchedRecommendations,
                savedRecommendations: updatedSavedRecommendations
            });
    }

    services.updateLocalStorage(constants.CONVO_UI_STATE, updatedState);
    return updatedState;
}