/*jshint esversion: 6 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../appState/actions';

class ConvoUIClose extends React.Component {
    constructor(props) {
        super(props);

        // Bind Statements
        this.declineAssistance = this.declineAssistance.bind(this);
    }

    declineAssistance() {
        this.props.actions.declineAssistance();
        UniversalAnalytics.SendEvent('Convo UI', 'Close Button Click', 'Close Button Click', 10);
    }

    render() {
        return (
            <div className="universalconvoui-sidebar-close">
                <button className="universalconvoui-sidebar-close-btn" onClick={this.declineAssistance}>
                    {this.props.globalState.dictionary.SidebarCloseButtonText}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        globalState: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConvoUIClose);